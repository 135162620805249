import { createContext } from "react";

import { buildUseCurrentBreakpointHook } from "@thelabnyc/thelabui/src/hooks/layout";

import { mediaQueries } from "../global-styles/media-queries";

/**
 * Hooks for components which need to react ot the current breakpoint.
 */
export const useCurrentBreakpoint = buildUseCurrentBreakpointHook(mediaQueries);

/**
 * Context used to pass down common page properties to downstream components
 */
export const pageContext = createContext<{
    title?: string;
    seoTitle?: string;
    searchDescription?: string;
}>({});

export const PageProvider = pageContext.Provider;
