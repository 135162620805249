import React from "react";

import { ColumnGridList as ColumnGridListValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils/hooks";
import { ButtonBorderless } from "../Clickable";
import { RichTextBlock } from "../RichText";

import styles from "./GridItem.module.scss";

type BreakpointState = {
    [query: string]: boolean;
};

const getColumnStyle = (gridCount: number, viewport: BreakpointState) => {
    if (viewport.belowTablet || gridCount <= 3) {
        return "span 4";
    }
    if (gridCount > 3) {
        return "span 3";
    }
    return "";
};

export const GridItem = (props: {
    value: ColumnGridListValue;
    color_options: "" | "darkMode" | "lightMode" | null | undefined;
    gridCount: number;
}) => {
    const viewport = useCurrentBreakpoint();
    const gridColumnSpan = getColumnStyle(props.gridCount, viewport);
    return (
        <section
            className={concatClassNames([
                styles.gridItem,
                props.color_options && props.color_options,
            ])}
            style={{
                gridColumn: gridColumnSpan,
            }}
        >
            {props.value.image && (
                <ImageChooserBlock
                    value={props.value.image}
                    attrs={{
                        className: styles.itemImage,
                    }}
                />
            )}
            {props.value.sub_body && (
                <RichTextBlock
                    className={styles.richText}
                    value={props.value.sub_body}
                    trimTerminalMargins="lastChild"
                />
            )}
            {props.value.cta?.display_name && (
                <ButtonBorderless
                    className={styles.cta}
                    href={props.value.cta?.url || ""}
                >
                    {props.value.cta?.display_name}
                </ButtonBorderless>
            )}
        </section>
    );
};
