import React from "react";

import { CmsValuePropListOrNull } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

import styles from "./ValueProp.module.scss";

type Value = Exclude<CmsValuePropListOrNull, null>[number];

export interface IProps {
    value: Value;
}

export const ValueProp = (props: IProps) => (
    <>
        {props.value.image && (
            <ImageChooserBlock
                attrs={{ className: styles.image }}
                value={props.value.image}
            />
        )}
        {props.value.video && (
            <VideoChooserBlock
                attrs={{ className: styles.video }}
                value={props.value.video}
            />
        )}
        <div className={styles.textBox}>
            <h2 className={styles.heading}>{props.value.title}</h2>
            <RichTextBlock value={props.value.copy} className={styles.text} />
        </div>
    </>
);
