import React, { useCallback, useEffect, useRef } from "react";

import { ColorOptionsSubBlockOrNull } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

export interface Props extends React.PropsWithChildren {
    color_options?: ColorOptionsSubBlockOrNull;
    /**
     * Children should have elements with `tabIndex={0}` and an onFocus handler
     * which will be passed into this component so that keyboard users can
     * navigate it
     */
    eventTarget: (EventTarget & HTMLElement) | null;
}

export const HorizontalScrollWrapper = (props: Props) => {
    const rootRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const inner = useRef<HTMLDivElement | null>(null);
    const maxHorizontalOffset = useRef(0);
    const isScrolling = useRef(false);

    /**
     * The amount that we want to scroll horizontally is mostly dependent on the width of the
     * horizontally scrolling content, but we don't want it to scroll completely out of view.
     * That's why we subtract the window width. Margins aren't part of width calculations
     * which is why it's also a part of this.
     */
    const setMaxHorizontalOffset = (element: HTMLDivElement | null) => {
        if (element && element.firstElementChild) {
            maxHorizontalOffset.current =
                element.scrollWidth -
                window.innerWidth +
                parseInt(
                    window.getComputedStyle(element.firstElementChild)
                        .marginLeft,
                );
        }
    };

    /**
     * This approach avoids the problem where you can't do something once the ref
     * changes from null because refs don't trigger a render
     */
    const innerRef = useCallback((element: HTMLDivElement) => {
        if (element !== null) {
            setMaxHorizontalOffset(element);
            inner.current = element;
        }
    }, []);

    const updateScrollTransforms = () => {
        if (!contentRef.current || !rootRef.current || !inner.current) {
            isScrolling.current = false;
            return;
        }

        const rootBox = rootRef.current.getBoundingClientRect();
        const contentBox = contentRef.current.getBoundingClientRect();
        let horizontalOffset = 0;
        let verticalOffset = 0;

        /** If the whole block starts to scroll out of view, start doing stuff */
        if (rootBox.top < 0) {
            /**
             * Fixed positioning was more reliable than sticky positioning, and
             * more performant than relative positioning with translateY working
             * against the scroll
             */
            contentRef.current.style.position = "fixed";

            /** This controls the horizontal scroll */
            if (rootBox.top > -maxHorizontalOffset.current) {
                horizontalOffset = rootBox.top;
            } else {
                horizontalOffset = -maxHorizontalOffset.current;
            }

            /** This controls the vertical scroll once you're past the block  */
            if (rootBox.bottom < contentBox.height) {
                verticalOffset = rootBox.bottom - contentBox.height;
            }
        } else {
            contentRef.current.style.position = "relative";
        }

        /**
         * You should never mutate the DOM like this in React. HOWEVER, this delivers
         * an immediate and thorough performance gain compared to useState.
         */
        contentRef.current.style.transform = `translate3d(0, ${verticalOffset}px, 0)`;
        inner.current.style.transform = `translate3d(${horizontalOffset}px, 0, 0)`;

        isScrolling.current = false;
    };

    const onScroll = () => {
        if (!isScrolling.current) {
            isScrolling.current = true;
            window.requestAnimationFrame(updateScrollTransforms);
        }
    };

    const onResize = () => {
        setMaxHorizontalOffset(inner.current);
        rootRef.current &&
            contentRef.current &&
            (rootRef.current.style.height = `${
                maxHorizontalOffset.current +
                contentRef.current.getBoundingClientRect().height
            }px`);
    };

    /**
     * Allows tabbing through "slides"
     * focusin on the rootRef and using document.activeElement is an alternative
     * if this gets too buggy
     */
    const jumpToTarget = () => {
        if (rootRef.current && props.eventTarget) {
            const offset = props.eventTarget.offsetLeft;
            const marginLeft = parseInt(
                getComputedStyle(props.eventTarget).marginLeft,
            );
            const rootBox = rootRef.current.offsetTop;
            window.scrollTo({
                top: rootBox + offset - marginLeft,
                behavior: "smooth",
            });
        }
    };

    /**
     * Tabbing wants to put an element in view by default but our custom scroll
     * doesn't work that way. You can't preventDefault a scroll but apparently
     * you can reset it.
     */
    const onContentScroll = (event: Event) => {
        (event.target as HTMLElement).scrollTo({ top: 0, left: 0 });
    };

    useEffect(() => {
        jumpToTarget();
    }, [props.eventTarget]);

    useEffect(() => {
        // Attach the event handler to update the active item whenever the user
        // scrolls the window.
        window.addEventListener("scroll", onScroll, { passive: true });
        window.addEventListener("resize", onResize, { passive: true });
        if (contentRef.current) {
            contentRef.current.addEventListener("scroll", onContentScroll, {
                passive: true,
            });
        }

        setMaxHorizontalOffset(inner.current);
        onResize();

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", onResize);
            if (contentRef.current) {
                contentRef.current.removeEventListener(
                    "scroll",
                    onContentScroll,
                );
            }
        };
    }, []);

    return (
        <section
            ref={rootRef}
            className={concatClassNames([styles.root, props.color_options])}
            style={{
                height:
                    maxHorizontalOffset.current +
                    (contentRef.current?.getBoundingClientRect().height || 0),
            }}
        >
            <article className={styles.content} ref={contentRef}>
                <div className={styles.inner} ref={innerRef}>
                    {props.children}
                </div>
            </article>
        </section>
    );
};
