import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import React from "react";

import { EmailSignupHeroBlock as EmailSignupHeroBlockValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonFilled } from "../Clickable";
import { EmailSignupModal } from "../EmailSignupModal";
import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

export const EmailSignupHeroBlock = (props: {
    value: EmailSignupHeroBlockValue;
}) => {
    const mediaAlign = props.value.alignment;
    return (
        <Dialog>
            <section
                className={concatClassNames([
                    styles.root,
                    mediaAlign && styles[mediaAlign],
                ])}
            >
                {props.value.media && (
                    <MediaChooserBlock
                        value={props.value.media}
                        dependencies={{
                            ImageChooserBlock: (props) => (
                                <ImageChooserBlock
                                    {...props}
                                    attrs={{
                                        className: concatClassNames([
                                            styles.image,
                                            mediaAlign && styles[mediaAlign],
                                        ]),
                                    }}
                                />
                            ),
                            VideoChooserBlock: (props) => (
                                <VideoChooserBlock
                                    {...props}
                                    attrs={{
                                        className: styles.video,
                                        controls: true,
                                    }}
                                />
                            ),
                        }}
                    />
                )}
                <div className={styles.textContainer}>
                    <div className={styles.contentOnMedia}>
                        {props.value.header && (
                            <h1 className={styles.header}>
                                {props.value.header}
                            </h1>
                        )}
                        {props.value.copy && (
                            <RichTextBlock value={props.value.copy} />
                        )}
                        {props.value.link && (
                            <DialogTrigger asChild>
                                <ButtonFilled className={styles.link}>
                                    {props.value.link}
                                </ButtonFilled>
                            </DialogTrigger>
                        )}
                    </div>
                </div>
            </section>
            <EmailSignupModal />
        </Dialog>
    );
};
