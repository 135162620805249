import React from "react";

import { EmailSignupBlock } from "../EmailSignupBlock";
import { Modal } from "../Modal";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const EmailSignupModal = () => (
    <Modal
        className={styles.emailSignupModal}
        contentLabel="Email Sign up Modal"
    >
        <Svg className={styles.logo} name="logo-chevron-only" />
        {/** Empty string for mimic EmailSignupBlock  */}
        <EmailSignupBlock value="" setting="modal" />
    </Modal>
);
