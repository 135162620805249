import React from "react";

import { MediaChooserBlock as MediaChooserBlockValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { MediaEmbedBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock/MediaEmbedBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";

import styles from "./HeroMediaBlock.module.scss";

const Block = (props: {
    value: MediaChooserBlockValue | null | undefined;
    videoAttrs?: React.VideoHTMLAttributes<HTMLVideoElement>;
    iOSFriendlyMutedAutoPlay: boolean;
}) => {
    if (!props.value) {
        return null;
    }
    return (
        <MediaChooserBlock
            value={props.value}
            dependencies={{
                ImageChooserBlock: (variantProps) => (
                    <ImageChooserBlock
                        {...variantProps}
                        attrs={{ className: styles.image }}
                    />
                ),
                VideoChooserBlock: (variantProps) => (
                    <VideoChooserBlock
                        {...variantProps}
                        attrs={{
                            className: styles.video,
                            ...props.videoAttrs,
                        }}
                        iOSFriendlyMutedAutoPlay={
                            props.iOSFriendlyMutedAutoPlay
                        }
                    />
                ),
                MediaEmbedBlock: (variantProps) => (
                    <MediaEmbedBlock
                        {...variantProps}
                        className={styles.embed}
                    />
                ),
            }}
        />
    );
};

export const HeroMediaBlock = React.memo(Block);
