import { useEffect } from "react";

export const useGtm = (
    containerId: string | null | undefined,
    nonce: string | null,
) => {
    const scriptEl = (scriptText: string) => {
        const script = document.createElement("script");
        script.innerHTML = scriptText;
        if (nonce) {
            script.nonce = nonce;
        }
        return script;
    };
    const noScriptEl = (noScriptText: string) => {
        const noScript = document.createElement("noscript");
        noScript.innerHTML = noScriptText;
        return noScript;
    };

    const scriptText = containerId
        ? `(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${containerId}');`
        : "";
    const noScriptText = containerId
        ? `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
                        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        : "";
    let [headScriptsAppended, bodyScriptsAppended] = [false, false];

    useEffect(() => {
        if (!headScriptsAppended && containerId) {
            document.head.insertBefore(
                scriptEl(scriptText),
                document.head.childNodes[0],
            );
            headScriptsAppended = true;
        }
        if (!bodyScriptsAppended && containerId) {
            document.body.insertBefore(
                noScriptEl(noScriptText),
                document.body.childNodes[0],
            );
            bodyScriptsAppended = true;
        }
    }, []);
};
