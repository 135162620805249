import React from "react";

import { RichTextBlock as Value } from "@reactivated";

import { RichTextBlock as LabUIRichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./rich-text.module.scss";

export interface Props {
    value: Value;
    className?: string | null;
    /**
     * Usually we want the margin-top of the rich text's first child and the
     * margin-bottom of the rich text's last child to be trimmed so they don't
     * affect the layout. But there are some cases where it's useful, so here
     * are a few ways out if you need them.
     */
    trimTerminalMargins?: "both" | "none" | "firstChild" | "lastChild";
    /**
     * When rich text is inside of a container, it gets extra styles.
     * It's a proxy for saying "this is probably an article / something
     * with longer body copy"
     */
    containedText?: boolean;
}

export const RichTextBlock = ({
    value,
    className,
    trimTerminalMargins = "both",
    containedText = false,
}: Props) => {
    return (
        <LabUIRichTextBlock
            value={value}
            className={concatClassNames([
                styles.richText,
                styles[trimTerminalMargins],
                className,
                containedText ? styles.containedText : "",
            ])}
        />
    );
};
