import React from "react";

import { PrinciplesBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { CircleAnimation } from "./CircleAnimation";
import { PrincipleTabs } from "./PrincipleTabs";
import { SelectedPrinciple } from "./SelectedPrinciple";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const PrinciplesBlock = (props: Props) => {
    const { principles_tabs: tabs } = props.value;
    const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
    if (!tabs) {
        return null;
    }
    const attrs = props.attrs || {};
    attrs.className = concatClassNames([
        attrs.className,
        styles.root,
        "darkMode",
    ]);
    const selectedTab = tabs[selectedTabIndex] || tabs[0];

    return (
        <section {...attrs}>
            <AnimateOnScroll animation="up">
                <article className={styles.content}>
                    <PrincipleTabs
                        tabs={tabs}
                        selectedTabIndex={selectedTabIndex}
                        onRequestTabChange={setSelectedTabIndex}
                    />
                    <CircleAnimation
                        index={selectedTabIndex}
                        className={styles.circle}
                    />
                    {selectedTab && <SelectedPrinciple tab={selectedTab} />}
                </article>
            </AnimateOnScroll>
        </section>
    );
};
