import React, { useEffect, useRef, useState } from "react";

import { ScrollAnimationSubBlock } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./index.module.scss";

type AnimationStyle =
    | ScrollAnimationSubBlock
    | "curtains"
    | "fade-in-only"
    | "reveal-from-left";

export const useAnimateOnScroll: <T extends HTMLElement>(
    animation?: AnimationStyle,
    threshold?: number,
    lowerMobileThreshold?: boolean,
) => [React.RefObject<T> | null, string, boolean] = <T extends HTMLElement>(
    animation?: AnimationStyle,
    threshold?: number,
    lowerMobileThreshold?: boolean,
) => {
    const fadeIn =
        animation === "left" ||
        animation === "up" ||
        animation === "fade-in-only";
    const initialStyles = concatClassNames([
        styles.animated,
        fadeIn ? styles.fadeIn : "",
        animation === "up" ? styles.up : "",
        animation === "left" ? styles.left : "",
        animation === "curtains" ? styles.curtains : "",
        animation === "reveal-from-left" ? styles.revealFromLeft : "",
    ]);
    const [isVisible, setIsVisible] = useState(false);
    const [previousTop, setPreviousTop] = useState(0);
    const [animateStyles, setAnimateStyles] = useState(initialStyles);
    const domRef = useRef<T | null>(null);
    const hasScrolled = useRef(false);

    useEffect(() => {
        /**
         * Featured Rich Text Block shifts the box to the left, which screws
         * this up unless we account for it. This adjustment solves the problem
         * in the least disruptive way.
         */
        const hardcodedLeftAdjustment = -32;
        const rect = domRef.current?.getBoundingClientRect();

        /**
         * If a user scrolls past the element, mark it so it doesn't fade out.
         * The `.left` line was added to make it work with HorizontalScrollBlock
         */
        const passedClass =
            rect && (rect.top < 0 || rect.left < hardcodedLeftAdjustment)
                ? styles.passed
                : "";

        setAnimateStyles(
            concatClassNames([
                initialStyles,
                isVisible ? styles.visible : "",
                passedClass,
            ]),
        );
    }, [isVisible, domRef]);

    useEffect(() => {
        const handleScroll = () => {
            hasScrolled.current = true;
        };

        window.addEventListener("scroll", handleScroll);
        const getAnimationThreshold = () => {
            if (typeof threshold === "number") return threshold;
            if (animation === "curtains") return 0.8;
            if (lowerMobileThreshold === true) return 0.15;
            /** If you change this, update the comment over the `threshold` prop */
            return 0.33;
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const isAboveTheFold =
                        entry.boundingClientRect.top >= 0 &&
                        !hasScrolled.current;

                    if (isAboveTheFold) {
                        setTimeout(
                            () => setIsVisible(entry.isIntersecting),
                            500,
                        );
                    } else {
                        setIsVisible(entry.isIntersecting);
                    }

                    setPreviousTop(entry.boundingClientRect.top);
                });
            },
            { threshold: getAnimationThreshold() },
        );

        if (domRef.current) observer.observe(domRef.current);

        return () => {
            if (domRef.current) observer.unobserve(domRef.current);
            window.removeEventListener("scroll", handleScroll);
        };
    }, [previousTop]);

    return [domRef, animateStyles, isVisible];
};

interface Props extends React.PropsWithChildren {
    animation?: AnimationStyle;
    attrs?: React.HTMLAttributes<HTMLDivElement>;
    wrapperAttrs?: React.HTMLAttributes<HTMLDivElement>;
    /** If nothing entered, defaults to 0.33 */
    threshold?: number;
    /** If an animated bit gets too vertical on mobile, try this */
    lowerMobileThreshold?: boolean;
}

export const AnimateOnScroll = ({
    animation,
    attrs,
    wrapperAttrs,
    threshold,
    lowerMobileThreshold,
    children,
}: Props) => {
    const [domRef, animateInStyles, isVisible] =
        useAnimateOnScroll<HTMLDivElement>(
            animation,
            threshold,
            lowerMobileThreshold,
        );

    if (animation === "left" || animation === "reveal-from-left") {
        return (
            <div
                {...wrapperAttrs}
                className={concatClassNames([
                    styles.wrapper,
                    animation === "reveal-from-left"
                        ? styles.revealWrapper
                        : undefined,
                    wrapperAttrs?.className,
                ])}
                ref={domRef}
            >
                <div
                    {...attrs}
                    className={concatClassNames([
                        attrs?.className,
                        animateInStyles,
                    ])}
                >
                    {children}
                </div>
            </div>
        );
    }

    if (
        animation === "up" ||
        animation === "curtains" ||
        animation === "fade-in-only"
    ) {
        return (
            <div
                ref={domRef}
                {...attrs}
                className={concatClassNames([
                    attrs?.className,
                    animateInStyles,
                ])}
            >
                {children}
            </div>
        );
    }

    return <div {...attrs}>{children}</div>;
};
