import React, { useEffect, useRef, useState } from "react";
import { clearBodyLocks, lock } from "tua-body-scroll-lock";

import { HeaderNavigationBodyBlock as HeaderNavigationBodyBlockValue } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { SocialMediaIcon } from "@thelabnyc/thelabui/src/components/SocialMediaIcon";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { LegalBlock } from "../LegalBlock/Legal";
import { Svg } from "../Svg";
import { BurgerMenu } from "./BurgerMenu";
import { MenuItemBlock } from "./MenuItem";

import styles from "./index.module.scss";

interface IProps {
    value: HeaderNavigationBodyBlockValue;
    overlapNav: boolean;
    overlapBrightness: number | false;
}

export const brightnessTippingPoint = 150;

export const HeaderNavigation = (props: IProps) => {
    const menuRef = useRef<HTMLDivElement | null>(null);
    const topNavRef = useRef<HTMLDivElement | null>(null);
    const [menuActive, setMenuActive] = useState(false);
    const [marginTop, setMarginTop] = useState(0);

    const handleResize = () => {
        setMarginTop(
            (props.overlapNav || menuActive) && topNavRef.current
                ? topNavRef.current.clientHeight - 1
                : 0,
        );
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [menuActive, topNavRef]);

    const theme =
        props.overlapBrightness === false
            ? "dark"
            : props.overlapBrightness > brightnessTippingPoint
              ? "dark"
              : "light";

    function keyHandler(event: KeyboardEvent) {
        if (event.key === "Escape" && menuActive) {
            setMenuActive(false);
        }
    }

    useEffect(() => {
        if (menuRef.current) {
            document.addEventListener("keydown", keyHandler);
        }

        if (menuActive && menuRef.current) {
            lock(menuRef.current);
        } else {
            clearBodyLocks();
        }

        return () => {
            clearBodyLocks();
            document.removeEventListener("keydown", keyHandler);
        };
    }, [menuActive]);

    return (
        <>
            <BurgerMenu
                menuActive={menuActive}
                setMenuActive={setMenuActive}
                className={styles[theme]}
            />
            <nav aria-label="Main Menu" className={styles.root}>
                <div
                    className={concatClassNames([
                        styles.topNav,
                        menuActive ? styles.topNavActive : "",
                        props.overlapNav ? styles.topNavOverlap : "",
                        styles[theme],
                    ])}
                    ref={topNavRef}
                >
                    <Clickable className={styles.logoClickable} href="/">
                        <Svg
                            name="logo-vertical"
                            visuallyHiddenText="Supernal Home Page"
                            className={styles.logo}
                        />
                    </Clickable>
                </div>
                <div
                    id="main-menu"
                    ref={menuRef}
                    className={concatClassNames([
                        styles.menu,
                        menuActive ? styles.menuActive : "",
                    ])}
                    style={{
                        marginTop,
                        height: `calc(100vh - ${marginTop}px)`,
                    }}
                >
                    <div className={styles.menuInner}>
                        <StreamField
                            components={{
                                menu_items: ({ value }) => (
                                    <>
                                        {value.map((item, index) => (
                                            <MenuItemBlock
                                                id={index}
                                                key={index}
                                                value={item}
                                                totalItems={value.length}
                                            />
                                        ))}
                                    </>
                                ),
                                links: () => <></>,
                                legal: () => <></>,
                                icons: () => <></>,
                            }}
                            value={props.value}
                            attrs={{ className: styles.menuColumnPrimary }}
                            tagName="ul"
                        />
                        <StreamField
                            components={{
                                menu_items: () => <></>,
                                links: ({ value }) => (
                                    <div
                                        className={styles.linksColumnContainer}
                                    >
                                        {value.map((link, index) => (
                                            <LinkSubBlock
                                                key={index}
                                                className={
                                                    styles.linksColumnItem
                                                }
                                                value={{
                                                    ...link,
                                                    link_style: "unstyled",
                                                }}
                                            />
                                        ))}
                                    </div>
                                ),
                                legal: ({ value }) => (
                                    <LegalBlock
                                        attrs={{
                                            className: styles.legalContainer,
                                        }}
                                        value={value}
                                    />
                                ),
                                icons: ({ value }) => (
                                    <div className={styles.socialContainer}>
                                        {value.map((icon, index) => (
                                            <SocialMediaIcon
                                                key={index}
                                                value={icon}
                                            />
                                        ))}
                                    </div>
                                ),
                            }}
                            value={props.value}
                            attrs={{ className: styles.menuColumnSecondary }}
                        />
                    </div>
                </div>
            </nav>
        </>
    );
};
