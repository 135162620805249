import React, { Dispatch, SetStateAction, useState } from "react";

import { ImageChooserBlockOrNull, TabbedSubBlock } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonBorderless } from "../Clickable";
import { PlaceholderImage } from "../Placeholder";
import { RichTextBlock } from "../RichText";

import styles from "./TabbedTile.module.scss";

export type BreakpointState = {
    [query: string]: boolean;
};

interface IProps {
    tab: TabbedSubBlock;
    tabIndex: number;
    isHovered: boolean;
    setHoveredTabIndex: Dispatch<SetStateAction<number>>;
    default_image: ImageChooserBlockOrNull | undefined;
}

export const TabbedTile = (props: IProps) => {
    const {
        tab: { content, include_more_less_toggle, image },
        tabIndex,
        isHovered,
        setHoveredTabIndex,
        default_image,
    } = props;
    const [readMoreLess, setReadMoreLess] = useState(false);

    const onReadMoreLess = () => setReadMoreLess((readMore) => !readMore);

    const onMouseOver = (
        setHoveredTabIndex: Dispatch<SetStateAction<number>>,
        tabIndex: number,
    ) => {
        setHoveredTabIndex(tabIndex);
    };

    return (
        <div
            onMouseOver={() => onMouseOver(setHoveredTabIndex, tabIndex)}
            className={concatClassNames([
                styles.container,
                default_image
                    ? styles.stackedOnMobile
                    : styles.inScrollableContainerOnMobile,
                !default_image && image ? styles.hoverTabs : "",
                isHovered && !default_image && image ? styles.activeTab : "",
            ])}
        >
            {!default_image && image && (
                <ImageChooserBlock
                    value={image}
                    attrs={{ className: styles.picture }}
                    imageAttrs={{ className: styles.image }}
                />
            )}
            {!default_image && !image && (
                <PlaceholderImage className={styles.placeholder} />
            )}
            {content && (
                <RichTextBlock
                    className={concatClassNames([
                        styles.body,
                        include_more_less_toggle
                            ? styles.hasReadMore
                            : styles.noReadMore,
                        !readMoreLess ? styles.showLess : undefined,
                    ])}
                    value={content}
                />
            )}
            {include_more_less_toggle && (
                <ButtonBorderless
                    className={styles.readMore}
                    onClick={onReadMoreLess}
                    icon={readMoreLess ? "minus" : "plus"}
                >
                    <span>{readMoreLess ? "Read less" : "Read more"}</span>
                </ButtonBorderless>
            )}
        </div>
    );
};
