import React from "react";

import { EcosystemPointOfInterestBlock } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./PointOfInterest.module.scss";

export const PointOfInterestClickable = (props: {
    value: EcosystemPointOfInterestBlock;
    isActive: boolean;
    onClick: () => void;
}) => (
    <Clickable className={styles.point} onClick={props.onClick}>
        <span className={styles.text}>{props.value.name}</span>
        <div
            className={concatClassNames([
                styles.icon,
                props.isActive ? styles.activeIcon : "",
            ])}
        ></div>
    </Clickable>
);
