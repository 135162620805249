import { ContainerReadyToolbox } from "@client/components/AlignedContainerBlock";
import { ArticleEventInfoBlock } from "@client/components/ArticleEventInfoBlock";
import { ArticleHeroBlock } from "@client/components/ArticleHeroBlock";
import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";

import styles from "../global-styles/templates/NewsArticleLandingPageTemplate.module.scss";

interface Props extends templates.NewsArticlePageTemplate {}

export const Template = ({ page }: Props) => (
    <React.StrictMode>
        <PageSkeleton {...page}>
            <ArticleHeroBlock
                value={{
                    parent: page.parent_page_link,
                    tags: page.article_tags,
                    title: page.article_title,
                }}
            />

            <div className={styles.body}>
                <ArticleEventInfoBlock value={{ ...page }} />
                <div>
                    <ContainerReadyToolbox value={page.body.value} />
                </div>
            </div>
        </PageSkeleton>
    </React.StrictMode>
);
