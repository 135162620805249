import React from "react";

import { ColumnGridBlock as Value } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { GridItem } from "./GridItem";

import styles from "./index.module.scss";

export const ColumnGridBlock = (props: { value: Value }) => {
    const { grid, color_options } = props.value;

    return (
        <section className={color_options ?? undefined}>
            <AnimateOnScroll
                animation="up"
                attrs={{ className: styles.gridContainer }}
            >
                <div className={styles.gridContent}>
                    {grid?.map((item, index) => (
                        <GridItem
                            key={`item-${index}`}
                            value={item}
                            color_options={color_options}
                            gridCount={grid.length}
                        />
                    ))}
                </div>
            </AnimateOnScroll>
        </section>
    );
};
