import React from "react";

import { NewsFeaturedArticlesBlock as Value } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { ArticleTile } from "./ArticleTile";
import { ContentGrid } from "./ContentGrid";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
}

export const NewsFeaturedArticlesBlock = (props: Props) => (
    <AnimateOnScroll
        animation="up"
        lowerMobileThreshold={true}
        attrs={{ className: styles.root }}
    >
        <h2 className={styles.header}>{props.value.header}</h2>
        <ContentGrid hideLastOnTablet={true} animate={false}>
            {props.value.articles.map((article) => (
                <ArticleTile key={article.id} article={article} />
            ))}
        </ContentGrid>
        {props.value.page && (
            <LinkSubBlock
                className={styles.allNewsButton}
                value={{
                    display_name: "See all news",
                    url: props.value.page?.url,
                    link_style: "",
                }}
            />
        )}
    </AnimateOnScroll>
);
