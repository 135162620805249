import React from "react";

import { MediaInquiryBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { ButtonFilled } from "../Clickable";
import { NewsContactInfo } from "../NewsContactInfo";
import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const MediaInquiryBlock = ({
    value: { body, contact_group, media_kit, media_kit_cta_text },
}: Props) => {
    if (!body && !contact_group && !media_kit) return <></>;
    return (
        <AnimateOnScroll animation="up" attrs={{ className: styles.root }}>
            <div className={styles.content}>
                {body && <RichTextBlock value={body} />}
                {media_kit && media_kit_cta_text && (
                    <ButtonFilled
                        className={styles.mediaKit}
                        href={media_kit.url}
                        download
                    >
                        {media_kit_cta_text}
                    </ButtonFilled>
                )}
            </div>
            <div
                className={concatClassNames([
                    styles.contacts,
                    contact_group?.body.length === 1
                        ? styles.singleContact
                        : "",
                ])}
            >
                {contact_group?.body.map((contact, index) => (
                    <NewsContactInfo key={index} value={contact.value} />
                ))}
            </div>
        </AnimateOnScroll>
    );
};
