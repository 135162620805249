import React from "react";

import { LegalBlock as LegalBlockValue } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { LinkSubBlock } from "../Clickable/LinkSubBlock";

import styles from "./Legal.module.scss";

interface IProps {
    value: LegalBlockValue;
    attrs?: React.HTMLAttributes<HTMLDivElement>;
}

// LegalBlock is a reusable component that can be used inside both Footer Nav and Header Nav.
export const LegalBlock = (props: IProps) => {
    const attrs = props.attrs || {};
    attrs.className = concatClassNames([
        attrs.className,
        styles.legalContainer,
    ]);
    return (
        <div {...attrs}>
            {props.value.links &&
                props.value.links.map((link, index) => (
                    <LinkSubBlock
                        key={index}
                        className={styles.legalLinkItem}
                        value={{ ...link, link_style: "unstyled" }}
                    />
                ))}
            {props.value.copyright && <p>{props.value.copyright}</p>}
        </div>
    );
};
