import React from "react";

import { EventsBlock } from "@reactivated";

import { ButtonBorderless } from "../Clickable";
import { EventInfo } from "../NewsHeroBlock/EventInfo";

import styles from "./EventTile.module.scss";

type EventArticle = EventsBlock["events"][number];

export interface Props {
    event: EventArticle;
}

export const EventTile = ({ event }: Props) => (
    <article className={styles.root}>
        <div className={styles.inner}>
            <h4 className={styles.eventTitle}>{event.headline}</h4>
            <EventInfo
                attrs={{ className: styles.eventInfo }}
                dateRange={event.event_daterange}
                location={event.location}
            />
            <ButtonBorderless href={event.page_url} className={styles.cta}>
                Learn more
            </ButtonBorderless>
        </div>
    </article>
);
