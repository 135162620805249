import React from "react";

import { templates } from "@reactivated";

import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

import { PageSkeleton } from "../components/PageSkeleton";
import { SpacerBlock } from "../components/SpacerBlock";
import { Toolbox } from "../components/Toolbox";

import styles from "../global-styles/templates/ContactUsLandingPageTemplate.module.scss";

export const Template = (props: templates.ContactUsLandingPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <Toolbox value={props.page.body.value} />
            <section className={styles.root}>
                <RichText
                    className={styles.message}
                    value={props.page.confirmation_message}
                />
            </section>
            <SpacerBlock
                value={{ height: "large", background_color: "white" }}
            />
        </PageSkeleton>
    </React.StrictMode>
);
