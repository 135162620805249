import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.BasePageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page} />
    </React.StrictMode>
);
