import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import React from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface Props extends React.PropsWithChildren {
    /** For accessibility purposes. Give a useful title please! */
    contentLabel: string | undefined;
    className?: string | undefined;
    closeClassName?: string | undefined;
    overlayClassName?: string | undefined;
    /**
     * Most likely, you'll have this as `true` if you need to animate the modal
     */
    forceMount?: true | undefined;
}

export const Modal = ({
    children,
    className,
    closeClassName,
    contentLabel,
    forceMount,
    overlayClassName,
}: Props) => {
    return (
        <Dialog.Portal>
            <Dialog.Overlay
                className={concatClassNames([styles.overlay, overlayClassName])}
            />
            <Dialog.Content className={className} aria-describedby={undefined}>
                <VisuallyHidden>
                    <Dialog.Title>{contentLabel}</Dialog.Title>
                </VisuallyHidden>

                <Dialog.Close asChild>
                    <Clickable
                        className={concatClassNames([
                            styles.close,
                            closeClassName,
                        ])}
                    >
                        <Svg name="x" />
                    </Clickable>
                </Dialog.Close>

                {children}
            </Dialog.Content>
        </Dialog.Portal>
    );
};
