import { Label } from "@radix-ui/react-label";
import * as Select from "@radix-ui/react-select";
import React, { ChangeEvent, Fragment, useId, useRef, useState } from "react";

import { Widget as DefaultWidget, FieldHandler } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./FormField.module.scss";

interface FieldClassNames {
    wrapper?: string;
    label?: string;
    input?: string;
    select?: string;
    textArea?: string;
    error?: string;
}

const formatPhoneNumber = (value: string) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})?/);
    if (!match) {
        return "";
    }
    return [
        match[1] ? "(" : "",
        match[1],
        match[2] ? ") " : "",
        match[2],
        match[3] ? "-" : "",
        match[3],
    ].join("");
};

const Widget = (props: {
    field: FieldHandler;
    classNames?: FieldClassNames;
}) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const textAreaWrapperRef = useRef<HTMLDivElement | null>(null);
    const checkboxID = useId();
    const field = props.field;

    /**
     * See notes on .textareaGrowing for more info, but this is setting the text
     * in a data-attr which is used by .textarea::after's content attribute to
     * help make the textarea height expand/contract with the text inside
     */
    const onTextareaInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
        if (!textAreaRef.current || !textAreaWrapperRef.current) return;
        textAreaWrapperRef.current.dataset.replicatedValue =
            event.currentTarget.value;
    };

    // Need to add this condition because the version 0.28.2a1334 of "reactivated" doesn't contain placeholder attribute
    // in form widgets: https://github.com/silviogutierrez/reactivated/blob/v0.28.2/packages/reactivated/src/forms/widgets.tsx
    // After having an updated version like v0.31.0 or later, we can remove this condition for TextInput form widget
    if (field.tag === "django.forms.widgets.EmailInput") {
        return (
            <>
                <label
                    className={concatClassNames([
                        props.classNames?.label,
                        field.value ? styles.selectedLabel : styles.label,
                    ])}
                >
                    {field.label}
                </label>
                <input
                    className={concatClassNames([
                        styles.emailInput,
                        field.error
                            ? styles.resetMargin
                            : props.classNames?.input,
                    ])}
                    onChange={(event) => field.handler(event.target.value)}
                    placeholder={field.widget.attrs.placeholder ?? ""}
                    type={field.widget.type ?? ""}
                    name={field.widget.name ?? ""}
                    value={field.value ?? ""}
                />
            </>
        );
    }
    if (field.tag === "django.forms.widgets.TextInput") {
        const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
            const position = e.target.selectionStart;
            let value = e.target.value;
            const previousValueLength = value.length;
            /**
             * Expected to be zero unless it strips something or it adds
             * phone number formatting characters like `(`, `)`, or `-`
             */
            let lengthChange = 0;
            if (field.widget.name === "phone") {
                value = formatPhoneNumber(value);
                lengthChange = value.length - previousValueLength;
            }
            field.handler(value);
            if (field.widget.name === "phone") {
                setTimeout(() => {
                    if (position !== null)
                        e.target.selectionEnd = position + lengthChange;
                });
            }
        };

        return (
            <>
                <label
                    className={concatClassNames([
                        props.classNames?.label,
                        field.value ? styles.selectedLabel : styles.label,
                    ])}
                >
                    {field.label}
                </label>
                <input
                    className={concatClassNames([
                        styles.input,
                        field.error
                            ? styles.resetMargin
                            : props.classNames?.input,
                    ])}
                    onChange={(event) => onInputChange(event)}
                    placeholder={field.widget.attrs.placeholder ?? ""}
                    type={field.widget.type ?? ""}
                    name={field.widget.name ?? ""}
                    value={field.value ?? ""}
                />
            </>
        );
    }
    if (field.tag === "django.forms.widgets.Textarea") {
        return (
            <>
                <label
                    className={concatClassNames([
                        props.classNames?.label,
                        field.value ? styles.selectedLabel : styles.label,
                    ])}
                >
                    {field.label}
                </label>
                <div
                    className={styles.textareaGrowing}
                    ref={textAreaWrapperRef}
                >
                    <textarea
                        ref={textAreaRef}
                        className={concatClassNames([
                            styles.input,
                            styles.textarea,
                            field.error
                                ? styles.resetMargin
                                : props.classNames?.textArea,
                        ])}
                        onInput={onTextareaInput}
                        onChange={(event) => field.handler(event.target.value)}
                        placeholder={field.widget.attrs.placeholder ?? ""}
                        name={field.widget.name ?? ""}
                        value={field.value ?? ""}
                    />
                </div>
            </>
        );
    }
    if (field.tag === "django.forms.widgets.CheckboxInput") {
        const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
            field.handler(event.target.checked);
            setIsCheckboxChecked(!isCheckboxChecked);
        };

        return (
            <div className={styles.checkboxContainer}>
                <input
                    id={checkboxID}
                    className={styles.checkboxInput}
                    type={field.widget.type ?? ""}
                    onChange={(event) => onCheckboxChange(event)}
                    name={field.widget.name ?? ""}
                    checked={field.value ?? false}
                />
                <div
                    className={concatClassNames([
                        styles.checkboxIcon,
                        isCheckboxChecked ? styles.checkboxIconChecked : null,
                    ])}
                >
                    {isCheckboxChecked && <Svg name="check" />}
                </div>
                <label
                    htmlFor={checkboxID}
                    className={concatClassNames([
                        props.classNames?.label,
                        styles.checkboxLabel,
                    ])}
                >
                    {field.label}
                </label>
            </div>
        );
    }
    if (field.tag === "django.forms.widgets.Select") {
        const id = field.name;

        return (
            <>
                <Label
                    className={concatClassNames([
                        props.classNames?.label,
                        field.value !== ""
                            ? styles.selectedLabel
                            : styles.label,
                    ])}
                    htmlFor={id}
                >
                    {field.label}
                </Label>
                <Select.Root
                    disabled={field.disabled}
                    name={field.name}
                    defaultValue={field.value || undefined}
                    onValueChange={field.handler}
                >
                    <Select.Trigger id={id} asChild>
                        <Clickable className={styles.selectTrigger}>
                            <Select.Value
                                placeholder={field.widget.attrs.placeholder}
                            />
                            <Select.Icon>
                                <Svg
                                    className={styles.arrow}
                                    name="caret"
                                    aria-hidden="true"
                                />
                            </Select.Icon>
                        </Clickable>
                    </Select.Trigger>

                    <Select.Portal>
                        <Select.Content className={styles.selectOptions}>
                            <Select.Viewport>
                                {field.widget.optgroups
                                    // might need revised, but this works with the
                                    // use case(s) we have right now
                                    .map(([_, optgroup, __]) => optgroup[0])
                                    .map(({ label, value }, index) => {
                                        const lastItemStyle =
                                            index ===
                                            field.widget.optgroups.length - 1
                                                ? styles.lastItem
                                                : "";

                                        return typeof value !== "string" ||
                                            value === "" ? (
                                            <Fragment key={`key-${index}`} />
                                        ) : (
                                            <Select.Item
                                                key={`key-${index}`}
                                                value={value}
                                                className={concatClassNames([
                                                    styles.selectOptionItem,
                                                    lastItemStyle,
                                                ])}
                                            >
                                                <Select.ItemText>
                                                    {label}
                                                </Select.ItemText>
                                                <Select.ItemIndicator />
                                            </Select.Item>
                                        );
                                    })}
                            </Select.Viewport>
                        </Select.Content>
                    </Select.Portal>
                </Select.Root>
            </>
        );
    }
    // TODO refine this as needed. If the input type is text-like, it shouldn't be
    // inside the label like this unless there's label text somewhere.
    return (
        <label className={props.classNames?.label}>
            <DefaultWidget field={field} />
        </label>
    );
};

export const FormField = (props: {
    classNames?: FieldClassNames;
    field: FieldHandler;
}) => {
    const field = props.field;
    return (
        <div
            className={concatClassNames([
                styles.fieldWrapper,
                props.classNames?.wrapper,
            ])}
        >
            <Widget field={field} classNames={props.classNames} />

            {!!field.error && (
                <p
                    className={concatClassNames([
                        styles.errorMessage,
                        props.classNames?.error,
                    ])}
                >
                    {field.error}
                </p>
            )}
        </div>
    );
};
