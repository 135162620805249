import React from "react";

import { MediaBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { MediaEmbedBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock/MediaEmbedBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    withinContainer?: boolean;
}

export const MediaBlock = ({ value, withinContainer }: Props) => (
    <section className={value.color_options ?? undefined}>
        <AnimateOnScroll animation="up">
            <article
                className={concatClassNames([
                    value.color_options ?? undefined,
                    !withinContainer ? styles.wrapper : "",
                ])}
            >
                {value.media && (
                    <MediaChooserBlock
                        value={value.media}
                        dependencies={{
                            ImageChooserBlock: (props) => (
                                <ImageChooserBlock
                                    {...props}
                                    attrs={{ className: styles.image }}
                                />
                            ),
                            VideoChooserBlock: (props) => (
                                <VideoChooserBlock
                                    {...props}
                                    attrs={{
                                        className: styles.video,
                                        controls: true,
                                    }}
                                />
                            ),
                            MediaEmbedBlock: (props) => (
                                <MediaEmbedBlock
                                    {...props}
                                    className={styles.embed}
                                />
                            ),
                        }}
                    />
                )}
            </article>
        </AnimateOnScroll>
    </section>
);
