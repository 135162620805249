import { Slide } from "pure-react-carousel";
import React from "react";

import { CarouselItemBlock as CarouselItemValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { MediaEmbedBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock/MediaEmbedBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./CarouselItem.module.scss";

interface SlideContentProps {
    slide: CarouselItemValue;
    hideCaptionOnTablet?: boolean;
}

export const SlideContent = ({
    slide,
    hideCaptionOnTablet,
}: SlideContentProps) =>
    slide.media ? (
        <>
            <MediaChooserBlock
                value={slide.media}
                dependencies={{
                    ImageChooserBlock: (props) => (
                        <ImageChooserBlock
                            {...props}
                            attrs={{ className: styles.image }}
                        />
                    ),
                    VideoChooserBlock: (props) => (
                        <VideoChooserBlock
                            {...props}
                            attrs={{
                                className: styles.video,
                                controls: true,
                            }}
                        />
                    ),
                    MediaEmbedBlock: (props) => (
                        <MediaEmbedBlock {...props} className={styles.embed} />
                    ),
                }}
            />

            <p
                className={concatClassNames([
                    styles.caption,
                    hideCaptionOnTablet ? styles.hideOnTablet : "",
                ])}
            >
                {slide.caption}
            </p>
        </>
    ) : (
        <></>
    );

interface Props {
    value: CarouselItemValue[];
    hideCaptionOnTablet?: boolean;
}

export const CarouselItem = ({ value, hideCaptionOnTablet }: Props) => (
    <>
        {value?.map((slide, index) => (
            <Slide index={index} key={index} className={styles.slide}>
                <SlideContent
                    slide={slide}
                    hideCaptionOnTablet={hideCaptionOnTablet}
                />
            </Slide>
        ))}
    </>
);
