import React from "react";

import styles from "./NewsTags.module.scss";

export const NewsTags = ({ tags }: { tags: string[] | null | undefined }) => {
    if (!tags) return <></>;
    if (tags.length === 0) return <></>;

    return (
        <div className={styles.tags}>
            {tags.map((tag, index) => (
                <div key={index} className={styles.tag}>
                    {tag}
                </div>
            ))}
        </div>
    );
};
