import React from "react";

import { EcosystemPointOfInterestBlock } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { Svg } from "../Svg";

import styles from "./PointOfInterestNavigator.module.scss";

interface Props {
    activePOIIndex: number;
    activePOI: EcosystemPointOfInterestBlock;
    sortedPOIs: EcosystemPointOfInterestBlock[];
    onClick: (activePOIindex: number) => void;
}

export const PointOfInterestNavigator = ({
    activePOIIndex,
    activePOI,
    sortedPOIs,
    onClick,
}: Props) => (
    <nav className={styles.root}>
        <Clickable
            className={styles.previousArrow}
            onClick={() => onClick(activePOIIndex - 1)}
            disabled={activePOIIndex < 1}
        >
            <Svg name="caret" visuallyHiddenText="Previous Point of Interest" />
        </Clickable>
        <p className={styles.text}>
            {activePOIIndex + 1}/{sortedPOIs.length}
            <span aria-hidden="true" className={styles.spacer} />
            {activePOI.name}
        </p>
        <Clickable
            className={styles.nextArrow}
            onClick={() => onClick(activePOIIndex + 1)}
            disabled={activePOIIndex >= sortedPOIs.length - 1}
        >
            <Svg name="caret" visuallyHiddenText="Next Point of Interest" />
        </Clickable>
    </nav>
);
