import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { TeamMembersBlock } from "../components/TeamMembersBlock";

export const Template = (props: templates.TeamMembersLandingPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <TeamMembersBlock
                value={{
                    header: `<h2>${props.page.title}</h2>`,
                    page: null,
                    ...props.page,
                }}
            />
        </PageSkeleton>
    </React.StrictMode>
);
