import { useEffect, useState } from "react";

import { HomepageHeroBlock } from "@reactivated";

import { GenericBody } from "./interfaces";

export const stringToRgba = (string: string) => {
    const match = string.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(1|0?.\d+))?\)$/,
    );
    return match
        ? {
              r: parseInt(match[1]),
              g: parseInt(match[2]),
              b: parseInt(match[3]),
              a: match[4] !== undefined ? parseFloat(match[4]) : 1,
          }
        : null;
};

/**
 * Similar to what thelabui does with root mean square; if it doesn't hold up
 * then feel free to improve it
 */
export const brightness = (r: number, g: number, b: number, a: number) => {
    // Assuming a translucent background is on white (255, 255, 255)
    const opacityAdjustment = (1 - a) * 255;
    const adjusted = {
        r: opacityAdjustment + a * r,
        g: opacityAdjustment + a * g,
        b: opacityAdjustment + a * b,
    };
    const relativeLuminance =
        (0.2126 * adjusted.r + 0.7152 * adjusted.g + 0.0722 * adjusted.b) / 255;
    return Math.sqrt(relativeLuminance) * 255;
};

/**
 * The color of the burger icon is dependent on the background of the
 * active block.
 *
 * This iterates through every element inside of the <main> and <footer>
 * elements and uses the background color to deliver a brightness value,
 * unless it's a special case that requires us to look at an image inside
 */
export const useBrightness = (
    mainRef: React.MutableRefObject<HTMLDivElement | null>,
    footerRef: React.MutableRefObject<HTMLDivElement | null>,
    bodyProp: GenericBody | null,
) => {
    /**
     * At least part of the nav will always overlap something, so inform it
     * about the brightness of what is under it
     */
    const [overlapBrightness, setOverlapBrightness] = useState<number | false>(
        false,
    );

    const getBrightness = () => {
        const main = mainRef.current;
        const footer = footerRef.current;

        const mainArray = main ? Array.from(main.children) : [];
        const footerArray = footer ? Array.from(footer.children) : [];
        const children = mainArray.concat(footerArray);

        /**
         * Anything with offsetTop - scrollY <= 0 is out of view (including
         * things with `display: none`, which we want to ignore); the last one
         * to trigger is the one that we want to determine the brightness value
         */
        const activeElement = children
            .filter(
                (node) =>
                    node instanceof HTMLElement &&
                    node.offsetTop - scrollY <= 0 &&
                    window
                        .getComputedStyle(node)
                        .getPropertyValue("display") !== "none",
            )
            .at(-1);
        if (!activeElement || bodyProp === null) {
            setOverlapBrightness(false);
            return overlapBrightness;
        }

        let newBrightness: number | false = false;

        /** Try to match the active DOM element to props.body */
        const activeBodyItem =
            activeElement.id !== "" &&
            bodyProp.value.find((item) => item.id === activeElement.id);

        const blockValue = bodyProp.value ? bodyProp.value[0].value : null;

        /**
         * If a scroll nav menu is present, then we need to figure out if it's
         * sticking to the top. If it is, then the burger needs to be black.
         */
        const scrollNavMenu = bodyProp.value.find(
            (item) => item.type === "scroll_nav_menu",
        );
        if (scrollNavMenu) {
            const node = children
                .filter(
                    (node): node is HTMLElement => node instanceof HTMLElement,
                )
                .filter(
                    (node) =>
                        node.getAttribute("data-id") === "scroll-nav-menu",
                )[0];
            const stickyCalc = node ? node.offsetTop - scrollY : -1;
            /** Ideally it'd be zero but scrollY is subpixel precise */
            if (node && stickyCalc < 1 && stickyCalc >= 0) {
                setOverlapBrightness(255);
                return 255;
            }
        }
        /**
         * Get into blocks and cast types to work with them
         */
        if (activeBodyItem && blockValue) {
            if (activeBodyItem.type === "homepage_hero") {
                const typedBlock = blockValue as HomepageHeroBlock;
                if (
                    typedBlock.background_media &&
                    typedBlock.background_media[0] &&
                    typedBlock.background_media[0].type === "image"
                )
                    newBrightness = Number(
                        typedBlock.background_media[0].value.brightness,
                    );
            }
            /**
             * This one has an overlay so we want it to be light all the time
             */
            if (activeBodyItem.type === "hero") {
                newBrightness = 1;
            }
        }
        /**
         * If there's still not a new brightness value, use the active element's
         * background color
         */
        if (!newBrightness) {
            const rgba = stringToRgba(
                getComputedStyle(activeElement).getPropertyValue(
                    "background-color",
                ),
            );
            if (rgba)
                newBrightness = brightness(rgba.r, rgba.g, rgba.b, rgba.a);
        }
        if (newBrightness !== overlapBrightness)
            setOverlapBrightness(newBrightness);
    };

    useEffect(() => {
        getBrightness();
        window.addEventListener("scroll", getBrightness);
        return () => {
            window.removeEventListener("scroll", getBrightness);
        };
    }, []);

    return overlapBrightness;
};
