import { Dialog } from "@radix-ui/react-dialog";
import React, { useState } from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { ButtonFilled } from "../Clickable";
import { Modal } from "../Modal";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";
import { TeamMemberDetails } from "./TeamMemberDetails";
import { TileGrid } from "./TileGrid";
import { TeamMember, TeamMembersBlockValue } from "./types";

import styles from "./index.module.scss";

export const TeamMembersBlock = (props: { value: TeamMembersBlockValue }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedMemberID, setSelectedMemberID] = useState<number | null>(
        null,
    );
    const members = props.value.team_members;
    const membersWithNav: TeamMember[] = members.map((member, index) => ({
        ...member,
        next: members[index + 1]?.id || members[0].id,
        previous: members[index - 1]?.id || members[members.length - 1].id,
    }));
    const selectedMemberIndex =
        membersWithNav.findIndex((member) => member.id === selectedMemberID) ||
        0;
    const selectedMember = membersWithNav[selectedMemberIndex];

    const onRequestClose = (open: boolean) =>
        !open && setSelectedMemberID(null);

    const onPrevious = () =>
        setSelectedMemberID(selectedMember?.previous || null);
    const onNext = () => setSelectedMemberID(selectedMember?.next || null);

    const onToggleExpanded = () => setIsExpanded((val) => !val);

    return (
        <section className={styles.container}>
            <Dialog onOpenChange={onRequestClose}>
                {props.value.header && (
                    <AnimateOnScroll
                        animation="left"
                        attrs={{ className: styles.header }}
                    >
                        <RichTextBlock
                            className={styles.richText}
                            value={props.value.header}
                        />
                    </AnimateOnScroll>
                )}

                <AnimateOnScroll
                    animation="up"
                    attrs={{ "className": styles.logo, "aria-hidden": "true" }}
                >
                    <Svg name="logo-chevron-only" />
                </AnimateOnScroll>
                <TileGrid
                    members={
                        isExpanded ? membersWithNav : membersWithNav.slice(0, 6)
                    }
                    onSelectMember={setSelectedMemberID}
                />
                {/** Render "Show more" button if members are more than 6 people */}
                {membersWithNav.length > 6 && (
                    <article className={styles.buttonContainer}>
                        <ButtonFilled onClick={onToggleExpanded}>
                            {isExpanded ? "Show less" : "Show more"}
                        </ButtonFilled>
                    </article>
                )}

                <Modal
                    contentLabel={selectedMember ? selectedMember.title : ""}
                    className={styles.modal}
                    forceMount
                >
                    {selectedMember && (
                        <TeamMemberDetails member={selectedMember} />
                    )}
                    <footer className={styles.navContainer}>
                        <div className={styles.nav}>
                            <Clickable
                                className={styles.previous}
                                onClick={onPrevious}
                            >
                                <Svg
                                    name="caret"
                                    visuallyHiddenText="View previous team member"
                                />
                            </Clickable>
                            {selectedMemberIndex + 1}/{members.length}
                            <Clickable className={styles.next} onClick={onNext}>
                                <Svg
                                    name="caret"
                                    visuallyHiddenText="View next team member"
                                />
                            </Clickable>
                        </div>
                    </footer>
                </Modal>
            </Dialog>
        </section>
    );
};
