import React from "react";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { TeamMemberTile } from "./TeamMemberTile";
import { TeamMember } from "./types";

import styles from "./TileGrid.module.scss";

export const TileGrid = (props: {
    members: TeamMember[];
    onSelectMember: (memberID: number) => void;
}) => (
    <AnimateOnScroll animation="up" attrs={{ className: styles.members }}>
        {props.members.map((member) => (
            <TeamMemberTile
                key={member.id}
                member={member}
                onClick={() => {
                    props.onSelectMember(member.id);
                }}
            />
        ))}
    </AnimateOnScroll>
);
