import * as Accordion from "@radix-ui/react-accordion";
import React from "react";

import { AccordionItems } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";

import styles from "./AccordionItem.module.scss";

interface Props {
    value: AccordionItems;
    index: string;
}

export const AccordionItem = ({ value, index }: Props) => (
    <Accordion.Item className={styles.item} value={index}>
        <Accordion.Header asChild>
            <div className={styles.headerWrapper}>
                {value.eyebrow && <RichTextBlock value={value.eyebrow} />}
                {value.header && (
                    <RichTextBlock
                        trimTerminalMargins="none"
                        className={concatClassNames([
                            styles.header,
                            !value.eyebrow ? styles.noEyebrow : "",
                        ])}
                        value={value.header}
                    />
                )}
            </div>
        </Accordion.Header>
        {value.body && (
            <>
                <Accordion.Content>
                    <RichTextBlock
                        trimTerminalMargins="firstChild"
                        value={value.body}
                    />
                </Accordion.Content>
                <Accordion.Trigger asChild>
                    <Clickable className={styles.trigger}>
                        <span className={styles.readMore}>
                            <Svg name="plus" aria-hidden="true" />
                            Read more
                        </span>
                        <span className={styles.readLess}>
                            <Svg name="minus" aria-hidden="true" />
                            Read less
                        </span>
                    </Clickable>
                </Accordion.Trigger>
            </>
        )}
    </Accordion.Item>
);
