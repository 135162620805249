import React, { useContext } from "react";

import { hooks } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { pageContext } from "../../utils/hooks";
import { ClickableMinimal } from "../Clickable";
import { Svg } from "../Svg";

import styles from "./MenuToggle.module.scss";

export const MenuToggle = (props: {
    isOpen: boolean;
    onToggle: () => void;
}) => {
    const activeItem = hooks.useActiveNavItem();
    const page = useContext(pageContext);
    return (
        <ClickableMinimal
            className={classNames([
                [styles.menuToggle, true],
                [styles.isOpen, props.isOpen],
            ])}
            onClick={props.onToggle}
        >
            <Svg name="caret" />
            {activeItem ? activeItem.name : page.title}
        </ClickableMinimal>
    );
};
