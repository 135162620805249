import React from "react";

import { RichTextBlockOrNull } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { FeaturedRichTextBlock } from "../FeaturedRichTextBlock";
import { Svg } from "../Svg";

// Keeping styles in this file so that we can compose wrapperBase without problems
import styles from "../ValuePropsBlock/index.module.scss";

export const HorizontalScrollHeader = ({
    header,
    desktopWidth,
}: {
    header: RichTextBlockOrNull;
    desktopWidth: "sixColumns" | "sevenColumns";
}) => (
    <div
        className={concatClassNames([
            styles.headerWrapper,
            styles[desktopWidth],
        ])}
    >
        {header && (
            <FeaturedRichTextBlock
                value={{
                    color_options: "darkMode",
                    animation_options: "left",
                    body: header,
                    cta: null,
                }}
                withinContainer={true}
                attrs={{ className: styles.featuredRichText }}
            />
        )}
        <AnimateOnScroll
            animation="left"
            wrapperAttrs={{
                className: styles.logoAnimationWrapper,
            }}
            attrs={{ className: styles.logoWrapper }}
            threshold={1}
        >
            <div className={styles.logo} aria-hidden="true">
                <div className={styles.logoInner}>
                    <Svg name="logo-chevron-only" />
                    <Svg name="logo-chevron-only" />
                </div>
            </div>
        </AnimateOnScroll>
    </div>
);
