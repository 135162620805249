import React from "react";

import { EcosystemRichTextBlockOrNull as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { ButtonFilled } from "../Clickable";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";

import featuredRichTextStyles from "../FeaturedRichTextBlock/index.module.scss";
import styles from "./EcosystemRichTextBlock.module.scss";

interface Props {
    value: Value;
    isVisible: boolean;
    isIntro: boolean;
    mainVideoEnded: boolean;
    mainVideoPlaying: boolean;
    advanceTimestamp: () => void;
}

const Icon = ({
    mainVideoEnded,
    isIntro,
}: {
    mainVideoEnded: boolean;
    isIntro: boolean;
}) => {
    if (mainVideoEnded) {
        return <Svg name="reload" />;
    } else if (!isIntro) {
        return <Svg name="arrow" />;
    }
    return <></>;
};

/**
 * Very similar to FeaturedRichTextBlock visually but there were some functional
 * augmentations that are unique to this block, so we're borrowing markup
 * structure and styles.
 */
export const EcosystemRichTextBlock = ({
    value,
    isVisible,
    isIntro,
    mainVideoEnded,
    mainVideoPlaying,
    advanceTimestamp,
}: Props) => {
    if (!value) return <></>;
    const { text, cta_text } = value;
    const isLargeHeader =
        text?.includes("<h1") || text?.includes("<h2") || false;

    const wrapperStyles = concatClassNames([
        styles.intro,
        isVisible ? styles.visible : styles.notVisible,
        "darkMode",
    ]);

    const onClick = () => {
        advanceTimestamp();
    };

    return (
        <AnimateOnScroll
            animation={isLargeHeader ? "left" : "up"}
            wrapperAttrs={{ className: isLargeHeader ? wrapperStyles : "" }}
            attrs={{ className: !isLargeHeader ? wrapperStyles : "" }}
        >
            <div
                className={concatClassNames([
                    featuredRichTextStyles.banner,
                    featuredRichTextStyles.bannerGridContainer,
                    isLargeHeader
                        ? featuredRichTextStyles.largeHeader
                        : undefined,
                ])}
            >
                {text && (
                    <RichTextBlock
                        className={featuredRichTextStyles.richText}
                        value={text}
                    />
                )}
                <ButtonFilled
                    className={concatClassNames([
                        styles.cta,
                        mainVideoPlaying ? styles.ctaHidden : "",
                    ])}
                    onDark={true}
                    onClick={onClick}
                >
                    {mainVideoEnded ? "Start Over" : cta_text}
                    <Icon mainVideoEnded={mainVideoEnded} isIntro={isIntro} />
                </ButtonFilled>
            </div>
        </AnimateOnScroll>
    );
};
