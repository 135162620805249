import React, { SetStateAction, forwardRef } from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./burger.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    menuActive: boolean;
    setMenuActive: (value: SetStateAction<boolean>) => void;
}

export const BurgerMenu = forwardRef<HTMLDivElement, Props>(
    ({ menuActive, setMenuActive, ...props }, ref) => (
        <div
            ref={ref}
            {...props}
            className={concatClassNames([
                styles.root,
                menuActive ? styles.active : "",
                props.className,
            ])}
        >
            <Clickable
                className={styles.burgerClickable}
                onClick={() => setMenuActive((state) => !state)}
                aria-haspopup
                aria-expanded={menuActive}
                aria-controls="main-menu"
            >
                <Svg
                    name="burger"
                    className={concatClassNames([
                        styles.burger,
                        menuActive ? styles.xIcon : undefined,
                    ])}
                    visuallyHiddenText={`${
                        menuActive ? "Close" : "Open"
                    } Header Navigation"`}
                />
            </Clickable>
        </div>
    ),
);
