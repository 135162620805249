import { reverse } from "@reactivated";

import {
    CSRF_HEADER,
    FetchError,
    loadCSRFToken,
} from "@thelabnyc/thelabui/src/utils/api";

/**
 * Submit the email signup form via an API call
 */
export const submitEmailSignup = async (email: string): Promise<void> => {
    const url = reverse("emailsignup-create");
    const data = new URLSearchParams();
    data.append("email", email);
    const request = new Request(url, {
        method: "POST",
        headers: {
            [CSRF_HEADER]: await loadCSRFToken(),
        },
        body: data,
    });
    const response = await fetch(request);
    if (!response.ok) {
        throw new FetchError(
            response,
            `Query response was not ok: ${response.status}`,
        );
    }
};
