import React from "react";

import { EventsBlock as EventsBlockValue } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { EventTile } from "./EventTile";

import styles from "./index.module.scss";

type EventArticle = EventsBlockValue["events"][number];

export interface Props {
    value: EventsBlockValue;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const EventsBlock = ({ value, attrs }: Props) => (
    <section {...attrs}>
        <AnimateOnScroll animation="up">
            <div
                className={concatClassNames([
                    styles.content,
                    value.events?.length === 3
                        ? styles.threeColumns
                        : styles.twoColumns,
                ])}
            >
                {value.events?.map((event: EventArticle, index) => (
                    <EventTile key={index} event={event} />
                ))}
            </div>
        </AnimateOnScroll>
    </section>
);
