import React, { forwardRef } from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import {
    IconMap,
    createConditionalIcons,
} from "@thelabnyc/thelabui/src/components/Clickable/ConditionalIcons";
import { ClickableProps } from "@thelabnyc/thelabui/src/components/Clickable/types";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { SVGName, Svg, allSvgNames } from "../Svg";

import styles from "./index.module.scss";

const conditionalIconMap: IconMap<SVGName> = {
    download: "download",
    external: "external",
};

const ConditionalIcons = createConditionalIcons(allSvgNames, Svg);

type InnerProps = ClickableProps & {
    fallbackIcon?: SVGName;
};

const Inner = ({ fallbackIcon, ...props }: InnerProps) => {
    const conditionalIconProps = {
        ...props,
        mappedConditionalIcons: conditionalIconMap,
    };
    const icon = ConditionalIcons({ ...conditionalIconProps });
    return (
        <div className={styles.clickableContent}>
            {props.children}
            {icon && <ConditionalIcons {...conditionalIconProps} />}
            {!icon && fallbackIcon && (
                <Svg
                    name={fallbackIcon}
                    visuallyHiddenText={null}
                    aria-hidden={true}
                />
            )}
        </div>
    );
};

export const ButtonFilled = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps & { onDark?: boolean }
>(function ButtonFilled({ onDark, ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.filledButton,
                onDark ? styles.onDark : undefined,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});

export const ButtonStroke = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonStroke({ onDark, ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.strokeButton,
                onDark ? styles.onDark : undefined,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <div className={styles.strokeInner}>
                <Inner {...props} />
            </div>
        </Clickable>
    );
});

export const ButtonBorderless = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ButtonBorderless({ onDark, ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.borderlessButton,
                onDark ? styles.onDark : undefined,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} fallbackIcon="arrow" />
        </Clickable>
    );
});

export const TextLink = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function TextLink({ onDark, ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.textLink,
                onDark ? styles.onDark : undefined,
                props.disabled ? styles.disabled : undefined,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});

export const ClickableMinimal = forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    ClickableProps
>(function ClickableMinimal({ onDark, ...props }, ref) {
    return (
        <Clickable
            {...props}
            ref={ref}
            className={concatClassNames([
                styles.minimallyViable,
                props.className ?? undefined,
            ])}
        >
            <Inner {...props} />
        </Clickable>
    );
});
