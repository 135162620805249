import React, { useState } from "react";

import { EmailSignupBlock as Value } from "@reactivated";

import { FetchError } from "@thelabnyc/thelabui/src/utils/api";

import { submitEmailSignup } from "../../utils/api";
import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { FormField } from "../FormField";

import styles from "./index.module.scss";

const EMAIL_FIELD_NAME = "email";

enum SubmitState {
    EDITING,
    SUBMITTING,
    SUBMITTED,
}

const EmailSignupForm = (props: {
    setting: "block" | "modal";
    value: string;
    error: string;
    submitState: SubmitState;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    onChange: (value: string | null) => void;
}) => {
    const isDisabled = props.submitState === SubmitState.SUBMITTING;
    const headingStyle = styles[`${props.setting}Heading`];
    return (
        <>
            <h2 className={headingStyle}>
                Stay in the know.
                <br />
                Sign up for updates.
            </h2>
            <form
                method="POST"
                onSubmit={props.onSubmit}
                className={styles.form}
                noValidate
            >
                <FormField
                    classNames={{
                        wrapper: styles.textField,
                        label: styles.label,
                        input: styles.input,
                    }}
                    field={{
                        tag: "django.forms.widgets.EmailInput",
                        name: EMAIL_FIELD_NAME,
                        value: props.value,
                        handler: props.onChange,
                        error: props.error,
                        widget: {
                            type: "email",
                            required: true,
                            template_name: "django/forms/widgets/email.html",
                            is_hidden: false,
                            name: EMAIL_FIELD_NAME,
                            value: props.value,
                            attrs: {
                                id: `id_${EMAIL_FIELD_NAME}`,
                                placeholder: "Enter your email",
                            },
                            tag: "django.forms.widgets.EmailInput",
                        },
                        label: "Email",
                        help_text: "",
                        disabled: isDisabled,
                    }}
                />
                <LinkSubBlock
                    className={styles.button}
                    value={{
                        display_name: "Submit",
                        link_style: "",
                        url: "",
                    }}
                />
            </form>
        </>
    );
};

const EmailSignupConfirmation = ({
    setting,
}: {
    setting: "block" | "modal";
}) => {
    const headingStyle = styles[`${setting}Heading`];
    return (
        <>
            <h2 className={headingStyle}>Thank you for signing up</h2>
            <p className={styles.successText}>
                You have successfully joined our list. You&rsquo;ll be the first
                to hear about latest Supernal news.
            </p>
        </>
    );
};

interface EmailSignupBlockProps {
    value: Value;
    setting?: "block" | "modal";
}

export const EmailSignupBlock = ({
    setting = "block",
    ...props
}: EmailSignupBlockProps) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const [submitState, setSubmitState] = useState(SubmitState.EDITING);

    const onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        // Clear errors
        setError("");
        setSubmitState(SubmitState.SUBMITTING);
        // Try to submit
        const formData = new FormData(event.currentTarget);
        const email = formData.get(EMAIL_FIELD_NAME)?.toString() || "";
        try {
            await submitEmailSignup(email);
            setSubmitState(SubmitState.SUBMITTED);
        } catch (e) {
            // Catch and display validation errors
            let errorMsg: string | null = null;
            if (e instanceof FetchError) {
                errorMsg =
                    e.response.status !== 500
                        ? await e.getFieldError(EMAIL_FIELD_NAME)
                        : null;
            }
            if (!errorMsg) {
                errorMsg = "Sorry, an error occurred. Please try again.";
            }
            setError(errorMsg);
            setSubmitState(SubmitState.EDITING);
        }
    };

    return (
        <>
            <AnimateOnScroll
                animation={setting === "block" ? "up" : "none"}
                attrs={{ className: styles[`${setting}Root`] }}
            >
                {submitState === SubmitState.SUBMITTED ? (
                    <EmailSignupConfirmation setting={setting} />
                ) : (
                    <EmailSignupForm
                        setting={setting}
                        value={value}
                        error={error}
                        submitState={submitState}
                        onSubmit={onSubmit}
                        onChange={(value) => setValue(value || "")}
                    />
                )}
            </AnimateOnScroll>
        </>
    );
};
