import React, { Fragment } from "react";

import { ContactsBlock as Value } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { RichTextBlock } from "../RichText";
import { ContactValue } from "../types";

import styles from "./index.module.scss";

const Contact = ({ name, company, email }: ContactValue) => (
    <address className={styles.contact}>
        <p>
            <strong>{name}</strong>
        </p>
        {company && <p>{company}</p>}
        {email && (
            <a href={`mailto:${email}`} className={styles.email}>
                {email}
            </a>
        )}
    </address>
);

interface Props {
    value: Value;
}

export const ContactsBlock = ({ value: { header, body } }: Props) => {
    if (!body || body.length === 0) return <></>;

    return (
        <section>
            {header && (
                <RichTextBlock
                    value={header}
                    trimTerminalMargins="firstChild"
                />
            )}
            <StreamField
                components={{
                    contact: ({ value }) => <Contact {...value} />,
                    contact_group: ({ value }) => (
                        <>
                            {value.body.map(({ id, value }) => {
                                if (!value || !value.contact) return <></>;
                                return <Contact key={id} {...value.contact} />;
                            })}
                        </>
                    ),
                }}
                value={body}
                tagName={Fragment}
            />
        </section>
    );
};
