import React from "react";

import { StaggeredImageTextSubBlock } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonBorderless } from "../../components/Clickable";
import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";

import styles from "./StaggeredTile.module.scss";

export interface Props {
    staggeredTile: StaggeredImageTextSubBlock;
    isTileFeatured: boolean;
    index: number;
}

export const StaggeredTile = ({
    staggeredTile: { image, body, link },
    isTileFeatured,
    index,
}: Props) => (
    <AnimateOnScroll
        animation={isTileFeatured ? "left" : "up"}
        attrs={{
            style: { order: isTileFeatured ? undefined : index },
            className: concatClassNames([
                styles.tileContainer,
                isTileFeatured ? styles.featured : "",
            ]),
        }}
        wrapperAttrs={{
            style: { order: isTileFeatured ? index : undefined },
            className: `${isTileFeatured ? styles.wrapper : ""} foo`,
        }}
    >
        {image && (
            <ImageChooserBlock
                value={image}
                attrs={{ className: styles.tileImage }}
            />
        )}
        <div className={styles.tileBody}>
            {body && <RichTextBlock value={body} />}
            {link?.display_name &&
                (link?.url && link.link_style === "borderless" ? (
                    <ButtonBorderless href={link.url}>
                        {link.display_name}
                    </ButtonBorderless>
                ) : (
                    <LinkSubBlock value={link} />
                ))}
        </div>
    </AnimateOnScroll>
);
