import React, { useEffect, useRef, useState } from "react";

import {
    NavMenuRefsProvider,
    hooks,
} from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils/hooks";
import { MenuToggle } from "./MenuToggle";
import { PageInfo } from "./PageInfo";

import styles from "./ScrollNavMenuContainer.module.scss";

export interface CustomCss extends React.CSSProperties {
    /**
     * One of the dividers needs to respect navStatus's height while taking the
     * full width of navMenuFullBleed. There are a couple of ways to do this;
     * this way had the least friction. If the dropdown menu ends up being
     * absolutely positioned, get rid of this and do it in a more conventional way.
     */
    "--divider-height": string;
}

export const ScrollNavMenuContainer = (
    props: React.PropsWithChildren & {
        type?: string | undefined;
        id?: string | undefined;
    },
) => {
    const viewport = useCurrentBreakpoint();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const fullBleedRef = useRef<HTMLDivElement | null>(null);
    const navLinksRef = useRef<HTMLUListElement | null>(null);
    const navStatusRef = useRef<HTMLDivElement | null>(null);

    // Whenever a nav item is selected, close the menu.
    const onCloseMenu = () => {
        setIsMenuOpen(false);
    };
    useEffect(() => {
        hooks.onNavItemClicked.on(onCloseMenu);
        return () => {
            hooks.onNavItemClicked.off(onCloseMenu);
        };
    }, []);

    return (
        <NavMenuRefsProvider value={{ fullBleedRef, navLinksRef }}>
            <div
                data-id="scroll-nav-menu"
                ref={fullBleedRef}
                className={styles.navMenuFullBleed}
                style={
                    {
                        "--divider-height": `${
                            navStatusRef.current
                                ? navStatusRef.current.clientHeight
                                : -1
                        }px`,
                    } as CustomCss
                }
            >
                <div className={styles.inner}>
                    <div className={styles.navStatus} ref={navStatusRef}>
                        {viewport.aboveTablet ? (
                            <PageInfo />
                        ) : (
                            <MenuToggle
                                isOpen={isMenuOpen}
                                onToggle={() => {
                                    setIsMenuOpen((open) => !open);
                                }}
                            />
                        )}
                    </div>
                    <nav
                        className={classNames([
                            [styles.navItems, true],
                            [styles.navIsOpen, isMenuOpen],
                        ])}
                    >
                        <ul ref={navLinksRef} className={styles.navItemList}>
                            {props.children}
                        </ul>
                    </nav>
                </div>
            </div>
        </NavMenuRefsProvider>
    );
};
