import React from "react";

import { FeaturedRichTextBlock as FeaturedRichTextBlockValue } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

interface Props {
    value: FeaturedRichTextBlockValue;
    /**
     * Set to true if this is being placed inside of another component.
     * If it's false, this component will add its own margins.
     */
    withinContainer?: boolean;
    attrs?: React.HTMLAttributes<HTMLElement>;
}

export const FeaturedRichTextBlock = ({
    value,
    withinContainer,
    attrs,
}: Props) => {
    const largeHeaders = ["<h1", "<h2"];
    const isLargeHeader = largeHeaders.some(
        (header) => value.body?.includes(header),
    );

    const rootAttrs = attrs || {};
    rootAttrs.className = concatClassNames([
        rootAttrs.className,
        styles.root,
        // If it's in a container, rely on container to specify dark mode
        !withinContainer ? value.color_options : undefined,
        // This combo results in an overflow: hidden that hides the
        // vertical line, so we need to adjust
        withinContainer && value.animation_options === "left"
            ? styles.withinContainerAndLeftAnimation
            : undefined,
    ]);

    return (
        <section {...rootAttrs}>
            <AnimateOnScroll
                animation={value.animation_options ?? ""}
                wrapperAttrs={{ className: styles.animationWrapper }}
            >
                <div
                    className={concatClassNames([
                        styles.banner,
                        isLargeHeader ? styles.largeHeader : undefined,
                        withinContainer
                            ? styles.bannerGrid
                            : styles.bannerGridContainer,
                    ])}
                >
                    {value.body && (
                        <RichTextBlock
                            className={concatClassNames([
                                styles.richText,
                                withinContainer ? styles.fullWidthRichText : "",
                            ])}
                            value={value.body}
                        />
                    )}
                    {value.cta?.display_name && (
                        <LinkSubBlock
                            className={styles.cta}
                            value={value.cta}
                            onDark={value.color_options === "darkMode"}
                        />
                    )}
                </div>
            </AnimateOnScroll>
        </section>
    );
};
