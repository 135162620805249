import { format, getYear, parseISO } from "date-fns";
import { MutableRefObject } from "react";

import {
    CharBlockOrNull,
    TimelineSubBlockList,
    TimelineSubBlockListOrNull,
} from "@reactivated";

// Helper functions to remove duplicated years
export const getYearsList = (timelines: TimelineSubBlockList) => {
    if (timelines) {
        // Remove duplicates
        const yearsList = timelines.map((time) => {
            if (!time.date) {
                return null;
            }
            const dateObj = parseISO(time.date);
            return format(dateObj, "yy");
        });
        const yearsSelected = [...new Set(yearsList)];
        return { yearsList, yearsSelected };
    }
    return {};
};

// Get the current thousand year of earth
export const getThousandYear = (
    header: CharBlockOrNull,
    timelines: TimelineSubBlockListOrNull | undefined,
) => {
    // Default thousand years value
    let thousandValue = "20";
    if (!header || !timelines) {
        return null;
    }
    if (timelines.length > 0) {
        // Get first thousand year value to show until 2100
        const date = new Date(timelines[0].date || "");
        thousandValue = getYear(date).toString().slice(0, 2);
    }
    return thousandValue;
};

// Sort given timelines by date
export const sortTimelines = (timelines: TimelineSubBlockList) =>
    [...timelines].sort((a, b) => {
        if (!a.date && !b.date) {
            return 0;
        }
        const prevDate = new Date(a.date as string | number | Date).getTime();
        const currentDate = new Date(
            b.date as string | number | Date,
        ).getTime();
        return prevDate - currentDate;
    });

const vwToPixels = (height: string) => {
    if (height.includes("vw")) {
        return (parseInt(height) / 100) * window.innerWidth;
    }
    return parseInt(height);
};

export function isHTMLElement(node: Element): node is HTMLElement {
    return node instanceof HTMLElement;
}

export const animateBigYear = (
    yearRef: MutableRefObject<HTMLButtonElement | null>,
    yearsContentRef: MutableRefObject<HTMLDivElement | null>,
    yearIndex: number,
    /**
     * The desktop design has the years go above the big year
     */
    adjustUpward: boolean,
) => {
    const itemHeight = yearRef?.current?.offsetHeight;
    if (itemHeight && yearsContentRef.current) {
        let heightAdjustment = 0;
        if (adjustUpward) {
            const height = getComputedStyle(
                yearsContentRef.current,
            ).getPropertyValue("--big-year-height");
            heightAdjustment = vwToPixels(height);
        }
        const translateY = yearIndex * itemHeight * -1 + heightAdjustment;
        yearsContentRef.current.style.transform = `translateY(${translateY}px)`;
    }
};

export const jumpToTimelineEntry = (
    timelinesContentRef: MutableRefObject<HTMLDivElement | null>,
    yearIndex: number,
    yearsSelected: (string | null)[] | undefined,
    bigYearRef: MutableRefObject<HTMLDivElement | null>,
    isChangingYear: MutableRefObject<boolean>,
    isScrolling: MutableRefObject<boolean>,
    isAboveTablet: boolean,
) => {
    if (
        timelinesContentRef.current &&
        bigYearRef.current &&
        isScrolling.current === false
    ) {
        isChangingYear.current = true;
        const bigYearBox = bigYearRef.current.getBoundingClientRect();

        if (bigYearBox.top < 0 || bigYearBox.top > window.innerHeight) return;

        const entryToUse = Array.from(
            timelinesContentRef.current.firstElementChild?.children || [],
        )
            .filter(isHTMLElement)
            .find((node) => {
                const year = node.dataset.year;
                if (year) {
                    return yearsSelected?.indexOf(year) === yearIndex;
                }
            });
        if (!entryToUse) return;

        if (isAboveTablet) {
            /**
             * "Focal point" is the vertical middle of the big year; when
             * an entry is near, we want the selected year to match its date
             */
            const focalPoint = bigYearBox.top + bigYearBox.height / 2;

            window.scrollTo({
                top:
                    document.documentElement.scrollTop +
                    entryToUse.getBoundingClientRect().top +
                    entryToUse.getBoundingClientRect().height / 2 -
                    focalPoint,
                behavior: "smooth",
            });
        } else {
            timelinesContentRef.current.firstElementChild?.scrollTo({
                left: entryToUse.offsetLeft - 16,
                behavior: "smooth",
            });

            setTimeout(() => (isChangingYear.current = false), 100);
        }
    }
};
