import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { TeamMemberDetails } from "../components/TeamMembersBlock/TeamMemberDetails";

export const Template = (props: templates.TeamMemberPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <TeamMemberDetails
                member={{
                    ...props.page,
                    bio: props.page.bio.html,
                    headshot: props.page.headshot?.block || null,
                    linkedin_profile: "",
                }}
            />
        </PageSkeleton>
    </React.StrictMode>
);
