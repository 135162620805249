import React, { useEffect, useState } from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonBorderless } from "../Clickable";

import styles from "./index.module.scss";

export const BackToTop = () => {
    const [visible, setVisible] = useState(false);

    const backToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        const onScroll = () => {
            setVisible(window.scrollY > window.innerHeight);
        };

        window.addEventListener("scroll", onScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <div className={styles.root}>
            <ButtonBorderless
                icon="caret-vertical"
                className={concatClassNames([
                    styles.clickable,
                    visible ? styles.visible : "",
                ])}
                onClick={backToTop}
            >
                Back to Top
            </ButtonBorderless>
        </div>
    );
};
