import React from "react";

import { PrinciplesSubBlock } from "@reactivated";

import { LinkSubBlock } from "../Clickable/LinkSubBlock";

import styles from "./SelectedPrinciple.module.scss";

interface Props {
    tab: PrinciplesSubBlock;
}

export const SelectedPrinciple = ({ tab }: Props) => (
    <div className={styles.body}>
        <p className={styles.subHeader}>{tab.sub_header}</p>
        {tab.link && tab.link?.display_name !== "" && (
            <LinkSubBlock className={styles.link} value={tab.link} onDark />
        )}
    </div>
);
