import React from "react";

import { FooterNavigationBodyBlock as FooterNavigationBodyBlockValue } from "@reactivated";

import { SocialMediaIcon } from "@thelabnyc/thelabui/src/components/SocialMediaIcon";
import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import meshWide from "../../../static/images/mesh-wide.svg";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { LegalBlock } from "../LegalBlock/Legal";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    value: FooterNavigationBodyBlockValue;
}

export const FooterNavigation = React.forwardRef<HTMLDivElement, IProps>(
    ({ value: propsValue, ...props }, ref) => (
        <footer
            {...props}
            ref={ref}
            className={concatClassNames([styles.root, props.className])}
        >
            <div className={styles.mainContentContainer}>
                <div className={styles.inner}>
                    <div className={styles.logo}>
                        <Svg
                            className={styles.logoSvg}
                            name="logo-horizontal"
                            visuallyHiddenText="Supernal Horizontal Logo"
                        />
                    </div>
                    <StreamField
                        components={{
                            links: ({ value }) => (
                                <>
                                    {value.map((link, index) => (
                                        <LinkSubBlock
                                            key={index}
                                            className={styles.linksColumnItem}
                                            value={link}
                                        />
                                    ))}
                                </>
                            ),
                            legal: () => <></>,
                            icons: () => <></>,
                        }}
                        value={propsValue}
                        attrs={{ className: styles.linksColumn }}
                        tagName="div"
                    />
                    <StreamField
                        components={{
                            links: () => <></>,
                            legal: ({ value }) => (
                                <LegalBlock
                                    attrs={{ className: styles.legalBlock }}
                                    value={value}
                                />
                            ),
                            icons: ({ value }) => (
                                <div
                                    className={styles.socialMediaIconContainer}
                                >
                                    {value.map((icon, index) => (
                                        <SocialMediaIcon
                                            key={index}
                                            value={icon}
                                        />
                                    ))}
                                </div>
                            ),
                        }}
                        value={propsValue}
                        attrs={{ className: styles.legalContainer }}
                        tagName="div"
                    />
                </div>
            </div>
            <div
                aria-hidden="true"
                className={styles.meshSvgContainer}
                style={{ backgroundImage: `url(${meshWide})` }}
            ></div>
        </footer>
    ),
);
