import React from "react";

import {
    HorizontalScrollBlock,
    HorizontalScrollSlideMedia,
} from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { SlideContent } from "../CarouselBlock/CarouselItem";
import { Modal } from "../Modal";

import styles from "./MediaModal.module.scss";

export const MediaModal = (props: {
    value: HorizontalScrollSlideMedia[];
    colorOptions: HorizontalScrollBlock["color_options"];
}) => (
    <Modal
        className={concatClassNames([
            styles.modal,
            props.colorOptions && styles[props.colorOptions],
        ])}
        contentLabel="More Photos"
    >
        {props.value.map((slideMedia, i) => (
            <article key={`${i}`} className={styles.media}>
                <SlideContent slide={slideMedia} hideCaptionOnTablet={false} />
            </article>
        ))}
    </Modal>
);
