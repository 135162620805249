import React from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./PlayControls.module.scss";

interface Props {
    mainVideoPlaying: boolean;
    startOver: () => void;
    togglePlay: () => void;
}

export const PlayControls = ({
    mainVideoPlaying,
    startOver,
    togglePlay,
}: Props) => (
    <div
        className={concatClassNames([
            styles.playControls,
            mainVideoPlaying ? styles.enlarged : "",
        ])}
    >
        <Clickable className={styles.playControl} onClick={startOver}>
            <Svg name="reload" visuallyHiddenText="Start Over" />
        </Clickable>
        <Clickable
            className={concatClassNames([
                styles.playControl,
                mainVideoPlaying ? styles.enlarged : "",
            ])}
            onClick={togglePlay}
        >
            {mainVideoPlaying ? (
                <Svg name="video-pause-fill" visuallyHiddenText="Pause" />
            ) : (
                <Svg name="video-play-fill" visuallyHiddenText="Play" />
            )}
        </Clickable>
    </div>
);
