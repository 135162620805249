import {
    ButtonBack,
    ButtonNext,
    CarouselProvider,
    CarouselContext as PureReactCarouselContext,
    Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { useContext, useEffect, useState } from "react";

import { CarouselBlock as CarouselBlockValue } from "@reactivated";

import labuiClickableStyles from "@thelabnyc/thelabui/src/components/Clickable/Clickable.module.scss";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";
import { CarouselItem } from "./CarouselItem";

import styles from "./index.module.scss";

const CarouselContext = (props: {
    onSlideChange: (currentSlide: number) => void;
}) => {
    const carouselContext = useContext(PureReactCarouselContext);

    useEffect(() => {
        const onChange = () => {
            props.onSlideChange(carouselContext.state.currentSlide);
        };
        carouselContext.subscribe(onChange);

        return () => carouselContext.unsubscribe(onChange);
    }, [carouselContext, props]);

    return <></>;
};

interface Props {
    value: CarouselBlockValue;
    withinContainer?: boolean;
    hideBottomOnTablet?: boolean;
}

export const CarouselBlock = ({
    value,
    withinContainer = false,
    hideBottomOnTablet,
}: Props) => {
    const { media } = value;
    const [currentSlide, setCurrentSlide] = useState<number>(1);
    const bigControls = !withinContainer;

    if (!media) return null;

    const hasAtLeastOneCaption =
        media.filter(
            ({ caption }) => typeof caption === "string" && caption !== "",
        ).length > 0;

    const onSlideChange = (selectedIndex: number) =>
        setCurrentSlide(selectedIndex + 1);

    return (
        <section className={!withinContainer ? styles.root : ""}>
            <CarouselProvider
                visibleSlides={1}
                naturalSlideHeight={400}
                naturalSlideWidth={225}
                totalSlides={media.length}
                isIntrinsicHeight={true}
                dragEnabled={false}
                className={styles.carousel}
                infinite={!withinContainer}
            >
                <article className={styles.content}>
                    <nav
                        className={concatClassNames([
                            styles.bottom,
                            hideBottomOnTablet ? styles.hideOnTablet : "",
                            hasAtLeastOneCaption ? styles.hasACaption : "",
                            bigControls
                                ? styles.bigControls
                                : styles.controlsBelow,
                        ])}
                    >
                        {media.length > 1 && (
                            <div className={styles.nav}>
                                <ButtonBack
                                    className={concatClassNames([
                                        labuiClickableStyles.buttonReset,
                                        bigControls
                                            ? styles.bigButtonBack
                                            : styles.smallButtonBack,
                                    ])}
                                >
                                    <Svg
                                        name="caret"
                                        visuallyHiddenText="Previous item in carousel"
                                    />
                                </ButtonBack>
                                <span className={styles.currentSlide}>
                                    {`${currentSlide}/${media.length}`}
                                </span>
                                <ButtonNext
                                    className={concatClassNames([
                                        labuiClickableStyles.buttonReset,

                                        !withinContainer
                                            ? styles.bigButtonNext
                                            : styles.smallButtonNext,
                                    ])}
                                >
                                    <Svg
                                        name="caret"
                                        visuallyHiddenText="Next item in carousel"
                                    />
                                </ButtonNext>
                            </div>
                        )}
                    </nav>
                    <Slider>
                        <CarouselItem
                            value={media}
                            hideCaptionOnTablet={hideBottomOnTablet}
                        />
                    </Slider>
                    {media.length > 1 && bigControls && (
                        <span className={styles.currentSlideBigControlsMobile}>
                            {`${currentSlide}/${media.length}`}
                        </span>
                    )}
                </article>
                <CarouselContext onSlideChange={onSlideChange} />
            </CarouselProvider>
        </section>
    );
};
