import React from "react";

import { NewsContactInfoBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { ContactValue } from "../types";

import styles from "./index.module.scss";

interface ContactProps {
    value: ContactValue;
}

export const Contact = ({ value: { name, email, avatar } }: ContactProps) => (
    <div className={styles.allButRole}>
        {avatar && (
            <ImageChooserBlock
                attrs={{ className: styles.picture }}
                imageAttrs={{ className: styles.image }}
                value={avatar}
            />
        )}
        <div className={styles.nameAndEmail}>
            <div className={styles.name}>{name}</div>
            {email && (
                <>
                    <div className={styles.divider} aria-hidden="true"></div>
                    <a href={`mailto:${email}`} className={styles.email}>
                        {email}
                    </a>
                </>
            )}
        </div>
    </div>
);

export interface Props {
    value: Value;
}

export const NewsContactInfo = (props: Props) => {
    const {
        value: { contact, role },
    } = props;
    if (!contact) return <></>;

    return (
        <address className={styles.root}>
            {role && <div className={styles.role}>{role}</div>}
            <Contact value={contact} />
        </address>
    );
};
