import React from "react";

import { templates } from "@reactivated";

import { LabUIToolbox } from "@thelabnyc/thelabui/src/components/LabUIToolbox";
import { RichText } from "@thelabnyc/thelabui/src/components/RichText";

import { PageSkeleton } from "../components/PageSkeleton";

export const Template = (props: templates.ExamplePageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <h1>{props.page.title}</h1>
            <hr />
            <RichText value={props.page.richtext} />
            <hr />
            <LabUIToolbox value={props.page.body.value} />
        </PageSkeleton>
    </React.StrictMode>
);
