import { VisuallyHidden } from "@react-aria/visually-hidden";
import React, { useContext } from "react";

import {
    Context,
    ServerCmsModelsPagesNewsArticlePageNewsArticlePageTemplate,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { SocialMediaShare } from "@thelabnyc/thelabui/src/components/SocialMediaShare";

import { ButtonBorderless, ButtonFilled } from "../Clickable";
import { Contact, NewsContactInfo } from "../NewsContactInfo";

import styles from "./index.module.scss";

export interface Props {
    value: Pick<
        ServerCmsModelsPagesNewsArticlePageNewsArticlePageTemplate["page"],
        | "article_asset_cta_text"
        | "article_asset_url"
        | "article_date_formatted"
        | "article_writer_object"
        | "contact_group"
        | "event_booth"
        | "event_daterange_formatted"
        | "event_location"
        | "event_logo"
        | "event_url"
        | "event_url_text"
    >;
}

export const ArticleEventInfoBlock = ({ value }: Props) => {
    const context = useContext(Context);

    return (
        <aside className={styles.root}>
            {value.event_logo && (
                <ImageChooserBlock
                    attrs={{ className: styles.image }}
                    value={value.event_logo.block}
                />
            )}
            <dl className={styles.list}>
                <div className={styles.dlItem}>
                    <dt className={styles.dt}>Date</dt>
                    <dd className={styles.dd}>
                        {value.event_daterange_formatted ??
                            value.article_date_formatted}
                    </dd>
                </div>
                {value.event_location && (
                    <div className={styles.dlItem}>
                        <dt className={styles.dt}>Location</dt>
                        <dd className={styles.dd}>{value.event_location}</dd>
                    </div>
                )}
                {value.event_booth && (
                    <div className={styles.dlItem}>
                        <dt className={styles.dt}>Booth</dt>
                        <dd className={styles.dd}>{value.event_booth}</dd>
                    </div>
                )}
                {value.event_url && (
                    <div className={styles.dlItem}>
                        <dt>
                            <VisuallyHidden>Event Link</VisuallyHidden>
                        </dt>
                        <dd>
                            <ButtonBorderless
                                target="_blank"
                                href={value.event_url}
                                className={styles.eventCta}
                            >
                                {value.event_url_text || "Visit the site"}
                            </ButtonBorderless>
                        </dd>
                    </div>
                )}
            </dl>
            {((value.contact_group &&
                value.contact_group.body.value.length > 0) ||
                value.article_writer_object) && (
                <div aria-hidden="true" className={styles.divider} />
            )}
            {value.contact_group &&
            value.contact_group.body.value.length > 0 ? (
                <div className={styles.contactGroup}>
                    {value.contact_group?.body.value.map((value, index) => (
                        <NewsContactInfo key={index} value={value.value} />
                    ))}
                </div>
            ) : value.article_writer_object ? (
                <div>
                    <div className={styles.role}>Author</div>
                    <Contact value={value.article_writer_object} />
                </div>
            ) : (
                <></>
            )}
            {value.article_asset_url && (
                <ButtonFilled download href={value.article_asset_url}>
                    {value.article_asset_cta_text || "Download assets"}
                </ButtonFilled>
            )}
            <div>
                <div className={styles.role}>Share</div>
                <SocialMediaShare
                    value={context.social_media_share?.share_widget.value}
                    buttonAttrs={{ className: styles.shareButton }}
                    successClassName={styles.copySuccess}
                    failureClassName={styles.copyFailure}
                />
            </div>
        </aside>
    );
};
