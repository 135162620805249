import React from "react";

import { PrinciplesSubBlock } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./PrincipleTabs.module.scss";

interface Props {
    tabs: PrinciplesSubBlock[];
    selectedTabIndex: number;
    onRequestTabChange: (index: number) => void;
}

export const PrincipleTabs = (props: Props) => (
    <div className={styles.principleTabs}>
        <ul className={styles.tabList}>
            {props.tabs.map((tab, index) => (
                <li key={`${index}`} className={styles.tabListItem}>
                    <Clickable
                        onDark
                        className={concatClassNames([
                            styles.tabItem,
                            index === props.selectedTabIndex
                                ? styles.selected
                                : undefined,
                        ])}
                        onMouseOver={() => props.onRequestTabChange(index)}
                        onClick={() => props.onRequestTabChange(index)}
                    >
                        <span className={styles.spacer} aria-hidden={true} />
                        <span className={styles.dotWrap} aria-hidden={true}>
                            <span className={styles.dot}></span>
                        </span>
                        <span>{tab.header}</span>
                    </Clickable>
                </li>
            ))}
        </ul>
    </div>
);
