import React from "react";

import { CSRFToken, ServerCmsForms, Widget, useForm } from "@reactivated";

import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { FormField } from "../FormField";

import styles from "./index.module.scss";

type Form = ServerCmsForms;

interface IProps {
    form: Form;
}

type FormFieldName = keyof Form["fields"];

type FieldRow = FormFieldName[];
type FieldRows = FieldRow[];

export const ContactForm = (props: IProps) => {
    const fieldRows: FieldRows = [
        ["category"],
        ["first_name", "last_name"],
        ["email"],
        ["phone"],
        ["company_name"],
        ["city"],
        ["message"],
        ["personal_data_consent"],
        ["signup_newsletter"],
    ];

    const form = useForm({
        form: props.form,
    });

    return (
        <section className={styles.root}>
            <form method="POST" className={styles.form}>
                <CSRFToken />
                {form.nonFieldErrors?.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}
                {form.hiddenFields.map((field, index) => (
                    <Widget key={index} field={field} />
                ))}
                {fieldRows.map((row, index) => (
                    <div className={styles.fieldRow} key={index}>
                        {row.map((fieldName) => (
                            <FormField
                                key={fieldName}
                                classNames={{
                                    input: styles.input,
                                    select: styles.select,
                                    textArea: styles.textArea,
                                }}
                                field={form.fields[fieldName]}
                            />
                        ))}
                    </div>
                ))}
                <LinkSubBlock
                    className={styles.submit}
                    value={{
                        display_name: "Submit",
                        link_style: "",
                        url: "",
                    }}
                />
            </form>
        </section>
    );
};
