import React from "react";

import { RichTextSnippetBlock as Value } from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";

import { RichTextWithOptionsBlock } from ".";

export const RichTextSnippetBlock = ({ value }: { value: Value }) => {
    if (!value.rich_text) {
        return null;
    }
    return (
        <StreamField
            components={{ rich_text: RichTextWithOptionsBlock }}
            value={value.rich_text.body}
            tagName={React.Fragment}
        />
    );
};
