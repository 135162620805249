import React, { useEffect, useState } from "react";

import {
    EcosystemPointOfInterestBlockList,
    EcosystemPointOfInterestBlock as POIValue,
} from "@reactivated";

import { sortBy } from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { PointOfInterestClickable } from "./PointOfInterest";
import { PointOfInterestNavigator } from "./PointOfInterestNavigator";

import styles from "./Timeline.module.scss";

interface CustomCss extends React.CSSProperties {
    "--progress": number;
}

const getPOIIsActive = (
    thisPOI: POIValue,
    nextPOI: POIValue | null,
    videoPlayheadPosition: number,
): boolean => {
    const thisActive =
        thisPOI.video_timestamp !== null &&
        videoPlayheadPosition >= thisPOI.video_timestamp;
    const nextActive =
        nextPOI &&
        nextPOI.video_timestamp !== null &&
        videoPlayheadPosition >= nextPOI.video_timestamp;
    return !!(thisActive && !nextActive);
};

interface Props {
    value: EcosystemPointOfInterestBlockList;
    activePOI: POIValue;
    onRequestSeek: (poi: POIValue) => void;
    videoRef: React.MutableRefObject<HTMLVideoElement | null>;
    isVisible: boolean;
}

export const Timeline = ({
    value,
    activePOI,
    onRequestSeek,
    videoRef,
    isVisible,
}: Props) => {
    const [progress, setProgress] = useState(0);
    const sortedPOIs = sortBy(value, "video_timestamp");
    const activePOIIndex = sortedPOIs.findIndex(
        (poi) => poi.video_timestamp === activePOI.video_timestamp,
    );

    const handleTimeUpdate = () => {
        if (!videoRef.current) return;
        if (!activePOI) return;

        if (activePOIIndex === sortedPOIs.length - 1) {
            setProgress(1);
            return;
        }

        const activePOITimestamp = activePOI.video_timestamp || 0;
        const nextPOITimestamp =
            sortedPOIs[activePOIIndex + 1]?.video_timestamp || 0;
        /**
         * Progress bar is a single line but the timestamps aren't guaranteed
         * to be uniformly spaced apart. We want the progress indicator to
         * hit the POIs at the correct times; this requires extra math.
         */
        const progressBetweenPOIs =
            (videoRef.current.currentTime - activePOITimestamp) /
            (nextPOITimestamp - activePOITimestamp);

        const activePOIPercent = activePOIIndex / (sortedPOIs.length - 1);
        const nextPOIPercent = (activePOIIndex + 1) / (sortedPOIs.length - 1);
        const progressRatio =
            activePOIPercent +
            progressBetweenPOIs * (nextPOIPercent - activePOIPercent);

        setProgress(progressRatio);
    };

    useEffect(() => {
        handleTimeUpdate();
        if (!videoRef.current) return;
        videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener(
                    "timeupdate",
                    handleTimeUpdate,
                );
            }
        };
    }, [activePOI]);

    if (!value) return null;

    const navigateActive = (index: number) => {
        onRequestSeek(sortedPOIs[index]);
    };

    return (
        <article
            className={concatClassNames([
                styles.timeline,
                isVisible ? styles.timelineActive : "",
            ])}
        >
            <div className={styles.timelineContent}>
                <div
                    className={styles.newDivider}
                    aria-hidden="true"
                    style={{ "--progress": progress } as CustomCss}
                />
                {sortedPOIs.map((thisPOI, i) => {
                    const nextPOI: POIValue | undefined = sortedPOIs[i + 1];
                    const isActive =
                        activePOI.video_timestamp !== null
                            ? getPOIIsActive(
                                  thisPOI,
                                  nextPOI,
                                  activePOI.video_timestamp,
                              )
                            : false;
                    return (
                        <PointOfInterestClickable
                            key={`${thisPOI.video_timestamp || 0}-${
                                thisPOI.name || ""
                            }`}
                            value={thisPOI}
                            isActive={isActive}
                            onClick={() => onRequestSeek(thisPOI)}
                        />
                    );
                })}
                {activePOI && (
                    <PointOfInterestNavigator
                        activePOIIndex={activePOIIndex}
                        activePOI={activePOI}
                        sortedPOIs={sortedPOIs}
                        onClick={navigateActive}
                    />
                )}
            </div>
        </article>
    );
};
