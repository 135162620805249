import React from "react";

import { PrincipleDetailsSubBlock } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { CircleAnimation } from "../PrinciplesBlock/CircleAnimation";
import { circleAnimationStyles } from "../PrinciplesBlock/circleAnimationStyles";
import { RichTextBlock } from "../RichText";

import styles from "./PrincipleDetail.module.scss";

interface Props {
    column: PrincipleDetailsSubBlock;
    columnIndex: number;
}

export const PrincipleDetail = (props: Props) => {
    // In case we have more tabs than defined styles, wrap the styles around.
    const styleIndex = props.columnIndex % circleAnimationStyles.length;
    return (
        <div className={styles.principleDetail}>
            <AnimateOnScroll
                animation="up"
                attrs={{
                    style: { transitionDelay: `${props.columnIndex / 10}s` },
                }}
            >
                <CircleAnimation index={styleIndex} className={styles.circle} />
                {props.column.content && (
                    <RichTextBlock
                        className={styles.content}
                        value={props.column.content}
                    />
                )}
            </AnimateOnScroll>
        </div>
    );
};
