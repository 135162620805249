import React, { useEffect, useRef, useState } from "react";

import { TimelineSubBlockList, TimelineBlock as Value } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils/hooks";
import { AnimateOnScroll } from "../AnimateOnScroll";
import { FeaturedRichTextBlock } from "../FeaturedRichTextBlock";
import { Svg } from "../Svg";
import { TimelineYear } from "./TimeLineYear";
import { TimelineEntry } from "./TimelineEntry";
import {
    animateBigYear,
    getThousandYear,
    getYearsList,
    isHTMLElement,
    jumpToTimelineEntry,
    sortTimelines,
} from "./helpers";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const TimelineBlock = ({ value: { header, timelines } }: Props) => {
    const viewport = useCurrentBreakpoint();

    const bigYearRef = useRef<null | HTMLDivElement>(null);
    const yearRef = useRef<null | HTMLButtonElement>(null);
    const yearsContentRef = useRef<HTMLDivElement | null>(null);
    const timelinesContentRef = useRef<HTMLDivElement | null>(null);

    /**
     * We have a feature where scrolling changes the year, and changing the
     * year scrolls. They'll mess each other up if we let them run together.
     */
    const isChangingYear = useRef(false);
    /**
     * On desktop, we're interested in the whole page scroll
     */
    const isScrollingPage = useRef(false);
    /**
     * On mobile, we don't care about the whole page scroll, we care about
     * the timeline entries scrolling horizontally
     */
    const isScrollingEntries = useRef(false);

    // Check timelines
    let sortedTimelines: TimelineSubBlockList | [] = [];
    if (timelines) {
        sortedTimelines = sortTimelines(timelines);
    }
    // Years list and years list without duplicates
    const { yearsList, yearsSelected } = getYearsList(sortedTimelines);

    // Local States
    const [selectedYearIndex, setSelectedYearIndex] = useState(0);
    const [bigYearHidden, setBigYearHidden] = useState(false);

    // Get Thousand year value
    const thousandValue = getThousandYear(header, sortedTimelines);

    const onResize = () => {
        animateBigYear(
            yearRef,
            yearsContentRef,
            selectedYearIndex,
            viewport.aboveTablet,
        );
    };

    let scrollEndTimer = -1;
    const scrollEnd = () => {
        if (scrollEndTimer != -1) clearTimeout(scrollEndTimer);
        scrollEndTimer = window.setTimeout(() => {
            isChangingYear.current = false;
            isScrollingPage.current = false;
        }, 200);
    };

    const activateScrollControl = () => {
        isScrollingEntries.current = true;
    };

    const deactivateScrollControl = () => {
        isScrollingEntries.current = false;
    };

    /**
     * TODO either retool mobile or make this work without competing scroll events
     */
    const onRightInnerScroll = (
        event: React.UIEvent<HTMLDivElement, UIEvent>,
    ) => {
        if (
            isChangingYear.current === false &&
            isScrollingEntries.current === true
        ) {
            const lastFullyVisibleEntry = Array.from(
                event.currentTarget.children || [],
            )
                .filter(isHTMLElement)
                // someday, can do findLast() instead of slice/reverse/find
                .slice()
                .reverse()
                .find((node) => {
                    const nodeBox = node.getBoundingClientRect();
                    return nodeBox.left + nodeBox.width < window.innerWidth;
                });
            const nodeYear = lastFullyVisibleEntry?.dataset.year;
            // Don't do this on mobile
            setBigYearHidden(false);
            if (nodeYear && yearsSelected)
                setSelectedYearIndex(yearsSelected.indexOf(nodeYear));
        }
    };

    const onScroll = () => {
        if (
            bigYearRef.current &&
            timelinesContentRef.current &&
            isChangingYear.current === false
        ) {
            isScrollingPage.current = true;
            const bigYearBox = bigYearRef.current.getBoundingClientRect();
            if (bigYearBox.top > 0 && bigYearBox.top < window.innerHeight) {
                /**
                 * "Focal point" is the vertical middle of the big year; when
                 * an entry is near, we want the selected year to match its date
                 */
                const focalPoint = bigYearBox.top + bigYearBox.height / 2;

                const closestEntry = Array.from(
                    timelinesContentRef.current.firstElementChild?.children ||
                        [],
                )
                    .filter(isHTMLElement)
                    .find((node) => {
                        const nodeBox = node.getBoundingClientRect();
                        return nodeBox.top + nodeBox.height > focalPoint;
                    });
                const nodeYear = closestEntry?.dataset.year;

                setBigYearHidden(!nodeYear);

                if (nodeYear && yearsSelected)
                    setSelectedYearIndex(yearsSelected.indexOf(nodeYear));
            }
        }
    };

    useEffect(() => {
        animateBigYear(
            yearRef,
            yearsContentRef,
            selectedYearIndex,
            viewport.aboveTablet,
        );
        jumpToTimelineEntry(
            timelinesContentRef,
            selectedYearIndex,
            yearsSelected,
            bigYearRef,
            isChangingYear,
            viewport.aboveTablet ? isScrollingPage : isScrollingEntries,
            viewport.aboveTablet,
        );

        window.addEventListener("resize", onResize, { passive: true });

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [selectedYearIndex]);

    useEffect(() => {
        window.addEventListener("scroll", onScroll, { passive: true });
        window.addEventListener("scroll", scrollEnd, { passive: true });

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("scroll", scrollEnd);
        };
    }, []);

    if (!yearsList && !yearsSelected) {
        return null;
    }

    const incrementYearIndex = () => {
        if (selectedYearIndex < yearsSelected.length - 1) {
            setSelectedYearIndex(selectedYearIndex + 1);
        }
    };

    const decrementYearIndex = () => {
        if (selectedYearIndex > 0) {
            setSelectedYearIndex(selectedYearIndex - 1);
        }
    };

    return (
        <section className={styles.root}>
            <div className={styles.left}>
                {header && (
                    <FeaturedRichTextBlock
                        value={{
                            color_options: "lightMode",
                            animation_options: "left",
                            body: `<h2>${header}</h2>`,
                            cta: null,
                        }}
                        withinContainer={true}
                    />
                )}
                <div
                    className={concatClassNames([
                        styles.bigYearWrapper,
                        bigYearHidden ? styles.bigYearHidden : "",
                    ])}
                    ref={bigYearRef}
                >
                    <AnimateOnScroll
                        animation="up"
                        attrs={{ className: styles.bigYear }}
                    >
                        <Clickable
                            className={styles.prev}
                            disabled={selectedYearIndex === 0}
                        >
                            <Svg
                                name="caret"
                                visuallyHiddenText="View previous year with a timeline entry"
                                onClick={decrementYearIndex}
                            />
                        </Clickable>

                        <div className={styles.thousandYear}>
                            {thousandValue}
                        </div>
                        <div className={styles.years}>
                            <div
                                ref={yearsContentRef}
                                className={styles.yearsContent}
                            >
                                {yearsSelected.map((year, i) => (
                                    <TimelineYear
                                        ref={yearRef}
                                        key={`key-${i}`}
                                        year={year}
                                        yearIndex={i}
                                        selected={selectedYearIndex === i}
                                        onYearSelect={setSelectedYearIndex}
                                    />
                                ))}
                            </div>
                        </div>

                        <Clickable
                            className={styles.next}
                            disabled={
                                selectedYearIndex === yearsSelected.length - 1
                            }
                        >
                            <Svg
                                name="caret"
                                visuallyHiddenText="View next year with a timeline entry"
                                onClick={incrementYearIndex}
                            />
                        </Clickable>

                        <div className={styles.logo} aria-hidden="true">
                            <Svg
                                className={styles.logoSvg}
                                name="logo-chevron-only"
                            />
                            <Svg
                                className={styles.logoSvg}
                                name="logo-chevron-only"
                            />
                        </div>
                    </AnimateOnScroll>
                </div>
            </div>
            <article ref={timelinesContentRef} className={styles.right}>
                <AnimateOnScroll
                    animation="up"
                    threshold={0.1}
                    attrs={{
                        className: styles.rightInner,
                        onTouchStart: activateScrollControl,
                        onMouseDown: activateScrollControl,
                        onTouchEnd: deactivateScrollControl,
                        onMouseUp: deactivateScrollControl,
                        onScroll: onRightInnerScroll,
                    }}
                >
                    {sortedTimelines?.map((entry, i) => (
                        <TimelineEntry key={`key-${i}`} value={entry} />
                    ))}
                </AnimateOnScroll>
            </article>
        </section>
    );
};
