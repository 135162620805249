import React, { FocusEvent, useState } from "react";

import { HorizontalScrollBlock as Value } from "@reactivated";

import { HorizontalScrollWrapper } from "../HorizontalScrollWrapper";
import { HorizontalScrollHeader } from "../HorizontalScrollWrapper/HorizontalScrollHeader";
import { SubBlock } from "./SubBlock";

interface Props {
    value: Value;
}

export const HorizontalScrollBlock = (props: Props) => {
    const [eventTarget, setEventTarget] = useState<HTMLElement | null>(null);

    const handleFocus = (event: FocusEvent<HTMLElement, Element>) =>
        event.target === event.currentTarget &&
        setEventTarget(event.currentTarget);

    return (
        <HorizontalScrollWrapper
            color_options={props.value.color_options}
            eventTarget={eventTarget}
        >
            {props.value.header && (
                <HorizontalScrollHeader
                    header={props.value.header}
                    desktopWidth="sevenColumns"
                />
            )}
            {props.value.carousel?.map((item, i) => (
                <SubBlock
                    key={`item-${i}`}
                    value={item}
                    colorOptions={props.value.color_options}
                    handleFocus={handleFocus}
                />
            ))}
        </HorizontalScrollWrapper>
    );
};
