import React, { forwardRef } from "react";

import { circleAnimationStyles } from "./circleAnimationStyles";

import styles from "./CircleAnimation.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    index: number;
}

export const CircleAnimation = forwardRef<HTMLDivElement, Props>(
    ({ index, ...props }, ref) => {
        // In case we have more tabs than defined styles, wrap the styles around
        const styleIndex = index % circleAnimationStyles.length;
        const selectedStyles = circleAnimationStyles[styleIndex];

        return (
            <div ref={ref} {...props} aria-hidden="true">
                <div className={styles.wrapper}>
                    <div className={styles.circleAnimation}>
                        <div
                            className={styles.midGray}
                            style={selectedStyles.midGray}
                        ></div>
                        <div
                            className={styles.darkGray}
                            style={selectedStyles.darkGray}
                        ></div>
                    </div>
                </div>
            </div>
        );
    },
);
