import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    EcosystemPointOfInterestBlock as POIValue,
    EcosystemBlock as Value,
} from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import {
    notEmptyOrBlank,
    sortBy,
} from "@thelabnyc/thelabui/src/utils/functional";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils/hooks";
import { EcosystemRichTextBlock } from "./EcosystemRichTextBlock";
import { PlayControls } from "./PlayControls";
import { Timeline } from "./Timeline";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const EcosystemBlock = ({ value }: Props) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const viewport = useCurrentBreakpoint();

    const blankRichText = { text: "", cta_text: "" };
    const introTimestamp = -1;
    const introPOI: POIValue = {
        name: "",
        text: value.intro_text ?? blankRichText,
        video_timestamp: introTimestamp,
    };
    const [activePOI, setActivePOI] = useState<POIValue>(introPOI);
    const activeTimestamp = activePOI.video_timestamp;
    const [mainVideoPlaying, setMainVideoPlaying] = useState(false);
    const [mainVideoEnded, setMainVideoEnded] = useState(false);

    const wrapperCallbackRef = useCallback((element: HTMLDivElement | null) => {
        if (element === null) return;
        const video = element.querySelector<HTMLVideoElement>("video");
        if (!video) return;
        videoRef.current = video;
    }, []);

    useEffect(() => {
        if (!videoRef.current) return;
        if (mainVideoPlaying === true) {
            void videoRef.current.play();
        } else {
            void videoRef.current.pause();
        }
    }, [mainVideoPlaying]);

    useEffect(() => {
        if (!videoRef.current) return;
        if (
            Math.round(videoRef.current.currentTime) !== activeTimestamp &&
            typeof activeTimestamp === "number"
        ) {
            videoRef.current.currentTime = activeTimestamp;
        }

        setMainVideoPlaying(activePOI.video_timestamp !== introTimestamp);

        if (wrapperRef.current) {
            window.scrollTo({
                top: wrapperRef.current.offsetTop,
                behavior: "smooth",
            });
        }
    }, [activePOI]);

    const allPOIs: POIValue[] = [
        value.intro_text ? introPOI : null,
        ...(value.timeline || []),
    ].filter(notEmptyOrBlank);
    const allPOIsSorted = sortBy(allPOIs, "video_timestamp");

    const handleTimestampChange = (poi: POIValue) => {
        setActivePOI(poi);
        setMainVideoEnded(false);
    };

    const setNextTimestamp = () => {
        const index = allPOIsSorted.findIndex(
            (poi) => poi.video_timestamp === activePOI.video_timestamp,
        );
        if (index > -1 && allPOIsSorted[index + 1]) {
            handleTimestampChange(allPOIsSorted[index + 1]);
        } else {
            handleTimestampChange(allPOIsSorted[0]);
        }
    };

    /**
     * onTimeUpdate emits the current index of the video in seconds.
     *
     * Loop through our point of interest timestamps; see if the current video
     * time is greater than a point of interest's timestamp and less than the
     * next POI's timestamp.
     *
     * If there's a match, update state.
     */
    const onTimeUpdate = (
        event: React.SyntheticEvent<HTMLVideoElement, Event>,
    ) => {
        const target = event.target as HTMLVideoElement;
        const currentTime = target.currentTime;
        const duration = target.duration;
        const timestampToActivate = allPOIsSorted.find((item, index) => {
            const nextItemTimestamp =
                index === allPOIsSorted.length - 1
                    ? duration
                    : allPOIsSorted[index + 1].video_timestamp;

            if (!item.video_timestamp || !nextItemTimestamp) return false;
            return (
                currentTime >= item.video_timestamp &&
                currentTime < nextItemTimestamp
            );
        })?.video_timestamp;

        if (
            typeof timestampToActivate === "number" &&
            timestampToActivate !== activeTimestamp
        ) {
            setMainVideoPlaying(false);
        }
    };

    const togglePlay = () => {
        if (!videoRef.current) return;
        videoRef.current.paused
            ? setMainVideoPlaying(true)
            : setMainVideoPlaying(false);
    };

    const videoToUse =
        viewport.belowMobile && value.background_video_mobile
            ? value.background_video_mobile
            : value.background_video;

    return (
        <section
            ref={wrapperRef}
            className={concatClassNames([
                styles.root,
                activeTimestamp === introTimestamp
                    ? styles.timelineNotVisible
                    : "",
            ])}
        >
            {activeTimestamp === introTimestamp &&
                value.background_intro_video && (
                    <div className={styles.videoWrapper}>
                        <VideoChooserBlock
                            value={value.background_intro_video}
                            attrs={{
                                className: styles.video,
                                controls: false,
                                autoPlay: true,
                                muted: true,
                                playsInline: true,
                                loop: true,
                                preload: "auto", // Load the video with pageload
                            }}
                            iOSFriendlyMutedAutoPlay={true}
                        />
                    </div>
                )}
            {activeTimestamp !== introTimestamp && videoToUse && (
                <div ref={wrapperCallbackRef} className={styles.videoWrapper}>
                    <VideoChooserBlock
                        key={videoToUse.sources[0].url}
                        value={videoToUse}
                        attrs={{
                            className: styles.video,
                            controls: false,
                            autoPlay: false,
                            muted: true,
                            playsInline: true,
                            loop: false,
                            onTimeUpdate,
                            onPlay: () => {
                                setMainVideoPlaying(true);
                                setMainVideoEnded(false);
                            },
                            onPause: () => setMainVideoPlaying(false),
                            onEnded: () => setMainVideoEnded(true),
                        }}
                    />
                </div>
            )}
            <div className={styles.container}>
                {allPOIsSorted.map((poi, index) => (
                    <EcosystemRichTextBlock
                        key={`${index}`}
                        value={poi.text ?? blankRichText}
                        isIntro={index === 0}
                        isVisible={
                            poi.video_timestamp === activePOI.video_timestamp
                        }
                        advanceTimestamp={setNextTimestamp}
                        mainVideoPlaying={mainVideoPlaying}
                        mainVideoEnded={mainVideoEnded}
                    />
                ))}
                {activeTimestamp !== introTimestamp && value.timeline && (
                    <PlayControls
                        mainVideoPlaying={mainVideoPlaying}
                        startOver={() => handleTimestampChange(introPOI)}
                        togglePlay={togglePlay}
                    />
                )}
                {value.timeline && (
                    <Timeline
                        value={value.timeline}
                        activePOI={activePOI}
                        onRequestSeek={handleTimestampChange}
                        videoRef={videoRef}
                        isVisible={activeTimestamp !== introTimestamp}
                    />
                )}
            </div>
        </section>
    );
};
