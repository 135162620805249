import React, { forwardRef } from "react";

import { DateBlockOrNull } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./TimelineYear.module.scss";

interface Props {
    year: DateBlockOrNull | string | null;
    yearIndex: number;
    selected: boolean;
    onYearSelect: (yearIndex: number) => void;
}

export const TimelineYear = forwardRef<HTMLButtonElement, Props>(
    ({ year, yearIndex, selected, onYearSelect }, ref) => (
        <Clickable
            ref={ref}
            className={concatClassNames([
                styles.year,
                selected ? styles.selectedYear : null,
            ])}
            onClick={() => onYearSelect(yearIndex)}
        >
            {year}
        </Clickable>
    ),
);
