import React from "react";

import { RichTextWithOptionsBlock as Value } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { RichTextBlock } from "../RichText";

interface Props {
    value: Value;
    /**
     * When rich text is inside of a container, it gets extra styles.
     * It's a proxy for saying "this is probably an article / something
     * with longer body copy"
     */
    containedText?: boolean;
}

export const RichTextWithOptionsBlock = ({ value, containedText }: Props) => {
    if (!value.body) return null;
    return (
        <AnimateOnScroll animation={value.animation_options ?? ""}>
            <RichTextBlock value={value.body} containedText={containedText} />
        </AnimateOnScroll>
    );
};
