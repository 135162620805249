import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { Context } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { PageProvider } from "../../utils/hooks";
import { BackToTop } from "../BackToTop";
import { FooterNavigation } from "../FooterNavigation";
import { HeaderNavigation } from "../HeaderNavigation";
import { useBrightness } from "./brightness";
import { useGtm } from "./gtm";
import { Props } from "./interfaces";

import "../../global-styles/global.module.scss";
import styles from "./index.module.scss";

export const PageSkeleton = (props: Props) => {
    const context = React.useContext(Context);
    const mainRef = React.useRef<HTMLDivElement | null>(null);
    const footerRef = React.useRef<HTMLDivElement | null>(null);

    /**
     * Sanity check in case GenericBody's assumptions don't hold and/or the
     * page doesn't have a `body` property at all
     */
    const bodyProp =
        props.body?._type === "StreamField" && Array.isArray(props.body.value)
            ? props.body
            : null;
    const type = bodyProp?.value[0]?.type || "";

    /**
     * If the first block in a body StreamField is a hero or a homepage_hero
     * then we want the nav to overlap.
     */
    const overlapNav = type === "hero" || type === "homepage_hero";
    /**
     * The burger icon and sometimes the logo will overlap stuff; use brightness
     * values to make sure the icon/logo are the correct color.
     */
    const brightness = useBrightness(mainRef, footerRef, bodyProp);
    /**
     * If a GTM Container ID is given, create GTM code snippets within script elements
     * and place them into <head> and <body> of web page's HTML output.
     */
    useGtm(
        context.core_assets.tracking?.gtm_container_id,
        context.request.csp_nonce,
    );

    const setGlobalCSSProperties = () => {
        if (document && window) {
            document.documentElement.style.setProperty(
                "--js-scrollbar-width",
                `${window.innerWidth - document.documentElement.clientWidth}px`,
            );
            document.documentElement.style.setProperty(
                "--js-vh",
                `${window.innerHeight * 0.01}px`,
            );
        }
    };

    useEffect(() => {
        setGlobalCSSProperties();
        window.addEventListener("resize", setGlobalCSSProperties);
        return () =>
            window.removeEventListener("resize", setGlobalCSSProperties);
    }, []);

    return (
        <PageProvider
            value={{
                title: props.title,
                seoTitle: props.seo_title,
                searchDescription: props.search_description,
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>{props.seo_title || props.title}</title>
                {props.search_description && (
                    <meta
                        name="description"
                        content={props.search_description}
                    />
                )}
                {props.robots && <meta name="robots" content={props.robots} />}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link
                    rel="icon"
                    href={context.core_assets.favicon}
                    type="image/x-icon"
                />
            </Helmet>
            <div
                className={concatClassNames([
                    styles.wrapper,
                    props.rootClassName,
                ])}
            >
                {context.header_nav && (
                    <HeaderNavigation
                        value={context.header_nav.body.value}
                        overlapNav={overlapNav}
                        overlapBrightness={brightness}
                    />
                )}
                {props.children && (
                    <main
                        className={concatClassNames([
                            styles.main,
                            props.mainClassName,
                        ])}
                        ref={mainRef}
                    >
                        {props.children}
                    </main>
                )}
                {context.footer_nav && (
                    <FooterNavigation
                        ref={footerRef}
                        value={context.footer_nav.body.value}
                    />
                )}
            </div>
            <BackToTop />
        </PageProvider>
    );
};
