import React from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";

import { NewsTags } from "../NewsHeroBlock/NewsTags";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export interface Props {
    value: {
        tags: { name: string }[];
        title: string;
        parent: {
            title: string;
            url: string;
        };
    };
}

export const ArticleHeroBlock = ({ value: { title, tags, parent } }: Props) => (
    <header className={styles.root}>
        <div className={styles.content}>
            <Clickable className={styles.breadcrumb} href={parent.url}>
                <Svg name="caret" aria-hidden="true" />
                {parent.title}
            </Clickable>
            <NewsTags tags={tags.map((tag) => tag.name)} />
            <h1 className={styles.heading}>{title}</h1>
        </div>
    </header>
);
