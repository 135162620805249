import React from "react";

import {
    ContainerReadyToolbox as ToolboxValue,
    AlignedContainerBlock as Value,
} from "@reactivated";

import { StreamField } from "@thelabnyc/thelabui/src/components/StreamField";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AccordionBlock } from "../AccordionBlock";
import { CarouselBlock } from "../CarouselBlock";
import { ContactsBlock } from "../ContactsBlock";
import { MediaBlock } from "../MediaBlock";
import { RichTextWithOptionsBlock } from "../RichTextWithOptions";
import { RichTextSnippetBlock } from "../RichTextWithOptions/RichTextSnippetBlock";
import { SpacerBlock } from "../SpacerBlock";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    id?: string | undefined;
    className?: string;
}

export const ContainerReadyToolbox = ({ value }: { value: ToolboxValue }) => (
    <StreamField
        components={{
            accordion: (props) => (
                <AccordionBlock {...props} withinContainer={true} />
            ),
            carousel: (props) => (
                <CarouselBlock {...props} withinContainer={true} />
            ),
            contacts: ContactsBlock,
            media: (props) => <MediaBlock {...props} withinContainer={true} />,
            rich_text_snippet: RichTextSnippetBlock,
            rich_text_with_options: (props) => (
                <RichTextWithOptionsBlock {...props} containedText={true} />
            ),
            spacer: SpacerBlock,
        }}
        value={value}
        tagName={React.Fragment}
    />
);

export const AlignedContainerBlock = ({ value, id, className }: Props) => {
    return (
        <div className={value.color_options === "darkMode" ? "darkMode" : ""}>
            <div
                className={concatClassNames([
                    styles.root,
                    value.alignment ? styles[value.alignment] : "",
                    value.width ? styles[value.width] : "",
                ])}
            >
                {value.items && <ContainerReadyToolbox value={value.items} />}
            </div>
        </div>
    );
};
