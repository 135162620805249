import React from "react";

import { CharBlockOrNull } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import styles from "./event-info.module.scss";

interface Props {
    dateRange: CharBlockOrNull | undefined;
    location: CharBlockOrNull | undefined;
    attrs?: React.HTMLAttributes<HTMLDivElement>;
}

export const EventInfo = ({ dateRange, location, attrs }: Props) => {
    if (!dateRange && !location) return <></>;

    return (
        <div
            {...attrs}
            className={concatClassNames([attrs?.className, styles.root])}
        >
            <div className={styles.content}>
                {dateRange && (
                    <p className={styles.item}>
                        <span className={styles.header}>Date</span>
                        {dateRange}
                    </p>
                )}
                {location && (
                    <p className={styles.item}>
                        <span className={styles.header}>Location</span>
                        {location}
                    </p>
                )}
            </div>
        </div>
    );
};
