import React, { FocusEvent, useState } from "react";

import { ValuePropsBlock as Value } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { HorizontalScrollWrapper } from "../HorizontalScrollWrapper";
import { HorizontalScrollHeader } from "../HorizontalScrollWrapper/HorizontalScrollHeader";
import { ValueProp } from "./ValueProp";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
}

export const ValuePropsBlock = (props: Props) => {
    const { header, value_props } = props.value;
    const [eventTarget, setEventTarget] = useState<HTMLElement | null>(null);

    const handleFocus = (event: FocusEvent<HTMLElement, Element>) =>
        event.target === event.currentTarget &&
        setEventTarget(event.currentTarget);

    return (
        <HorizontalScrollWrapper
            color_options="darkMode"
            eventTarget={eventTarget}
        >
            <HorizontalScrollHeader header={header} desktopWidth="sixColumns" />
            {value_props?.map((prop, index) => (
                <AnimateOnScroll
                    key={`${prop.id}-${index}`}
                    animation="up"
                    attrs={{
                        className: styles.itemWrapper,
                        tabIndex: 0,
                        onFocus: handleFocus,
                    }}
                    threshold={index === 0 ? 0.1 : 0.2}
                >
                    <ValueProp value={prop} />
                </AnimateOnScroll>
            ))}
        </HorizontalScrollWrapper>
    );
};
