import React from "react";

import {
    RichTextBlock as RichTextBlockValue,
    RichTextField as RichTextFieldValue,
} from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import {
    RichText,
    RichTextBlock,
} from "@thelabnyc/thelabui/src/components/RichText";

import { Svg } from "../Svg";
import { TeamMember } from "./types";

import styles from "./TeamMemberDetails.module.scss";

const isRichTextField = (
    rtf: RichTextFieldValue | RichTextBlockValue,
): rtf is RichTextFieldValue => {
    return (rtf as RichTextFieldValue).html !== undefined;
};

export const TeamMemberDetails = (props: { member: TeamMember }) => {
    return (
        <article className={styles.teamMemberDetail}>
            {props.member.headshot && (
                <div className={styles.headshot}>
                    <ImageChooserBlock value={props.member.headshot} />
                </div>
            )}
            <h3 className={styles.name}>{props.member.title}</h3>
            <p className={styles.jobTitle}>{props.member.job_title}</p>
            {isRichTextField(props.member.bio) ? (
                <RichText className={styles.bio} value={props.member.bio} />
            ) : (
                <RichTextBlock
                    className={styles.bio}
                    value={props.member.bio}
                />
            )}
            {props.member.linkedin_profile && (
                <Clickable
                    className={styles.linkedin}
                    href={props.member.linkedin_profile}
                >
                    <Svg name="linkedin" />
                </Clickable>
            )}
        </article>
    );
};
