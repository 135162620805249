import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { NewsroomToolboxBlock } from "../components/Toolbox";

export const Template = (props: templates.NewsCoverageLandingPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <NewsroomToolboxBlock value={props.page.body.value} />
        </PageSkeleton>
    </React.StrictMode>
);
