import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import React from "react";

import {
    MediaChooserBlockOrNull,
    HomepageHeroBlock as Value,
} from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { Modal } from "../Modal";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export interface IProps {
    id?: string | undefined;
    type?: string | undefined;
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const BackgroundMedia = ({
    value,
}: {
    value: MediaChooserBlockOrNull;
}) => {
    if (value === null) return <></>;
    return (
        <MediaChooserBlock
            value={value}
            dependencies={{
                ImageChooserBlock: (props) => (
                    <div className={styles.mediaWrapper}>
                        <ImageChooserBlock
                            {...props}
                            attrs={{ className: styles.backgroundImage }}
                        />
                    </div>
                ),
                VideoChooserBlock: (props) => (
                    <div className={styles.mediaWrapper}>
                        <VideoChooserBlock
                            {...props}
                            attrs={{
                                className: styles.backgroundVideo,
                                controls: false,
                                autoPlay: true,
                                loop: true,
                                muted: true,
                            }}
                            iOSFriendlyMutedAutoPlay={true}
                        />
                    </div>
                ),
                // Not supporting at this time
                MediaEmbedBlock: () => null,
            }}
        />
    );
};

export const HomepageHeroBlock = (props: IProps) => {
    const attrs = props.attrs || {};
    attrs.className = concatClassNames([
        attrs.className,
        styles.root,
        "darkMode",
    ]);
    const { background_media, headline, video, video_cta, body, cta } =
        props.value;

    return (
        <Dialog>
            <section {...attrs} id={props.id}>
                <BackgroundMedia value={background_media} />
                <article
                    className={concatClassNames([
                        styles.intro,
                        background_media && background_media.length > 0
                            ? styles.introAfterMedia
                            : "",
                    ])}
                >
                    <AnimateOnScroll
                        animation="left"
                        wrapperAttrs={{ className: styles.grid1 }}
                    >
                        {headline && (
                            <RichTextBlock
                                className={styles.headline}
                                value={headline}
                            />
                        )}
                    </AnimateOnScroll>
                    {video && (
                        <AnimateOnScroll
                            animation="fade-in-only"
                            attrs={{ className: styles.grid2 }}
                        >
                            <div
                                className={styles.video}
                                style={{
                                    background: `url(${
                                        video?.poster || ""
                                    }) no-repeat center center`,
                                    backgroundSize: `cover`,
                                }}
                            >
                                <DialogTrigger asChild>
                                    <Clickable className={styles.videoButton}>
                                        <Svg name="video-play-fill" />
                                        {video_cta}
                                    </Clickable>
                                </DialogTrigger>
                            </div>
                        </AnimateOnScroll>
                    )}
                </article>
                <article className={styles.mission}>
                    <AnimateOnScroll
                        animation="up"
                        threshold={0.15}
                        attrs={{ className: styles.missionGrid }}
                    >
                        {body && (
                            <RichTextBlock
                                className={styles.missionText}
                                value={body}
                            />
                        )}
                        {cta?.display_name && (
                            <LinkSubBlock className={styles.cta} value={cta} />
                        )}
                    </AnimateOnScroll>
                    <div className={styles.grid2}></div>
                </article>
            </section>
            <Modal className={styles.modal} contentLabel={video?.title}>
                <div className={styles.modalContent}>
                    <div className={styles.modalVideo}>
                        {video && <VideoChooserBlock value={video} />}
                    </div>
                </div>
            </Modal>
        </Dialog>
    );
};
