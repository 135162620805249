import { SupernalLinkSubBlock } from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import {
    LinkSubBlockClickableMap,
    createLinkSubBlockComponent,
} from "@thelabnyc/thelabui/src/components/LinkSubBlock";

import * as clickables from ".";

const clickableMap: LinkSubBlockClickableMap<
    NonNullable<SupernalLinkSubBlock["link_style"] | "unstyled">
> = {
    "": clickables.ButtonFilled,
    "stroke": clickables.ButtonStroke,
    "borderless": clickables.ButtonBorderless,
    "text-link": clickables.TextLink,
    "unstyled": Clickable,
};

export const LinkSubBlock = createLinkSubBlockComponent(clickableMap);
