import React from "react";

import { templates } from "@reactivated";

import { PageSkeleton } from "../components/PageSkeleton";
import { Toolbox } from "../components/Toolbox";

export const Template = (props: templates.HomePageTemplate) => {
    return (
        <React.StrictMode>
            <PageSkeleton {...props.page}>
                <Toolbox value={props.page.body.value} />
            </PageSkeleton>
        </React.StrictMode>
    );
};
