import React from "react";

import { AlignedHeroBlock as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { MediaEmbedBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock/MediaEmbedBlock";
import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useCurrentBreakpoint } from "../../utils/hooks";
import { AnimateOnScroll } from "../AnimateOnScroll";
import { FeaturedRichTextBlock } from "../FeaturedRichTextBlock";
import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

interface Props {
    value: Value;
}

export const AlignedHeroBlock = ({ value }: Props) => {
    const viewport = useCurrentBreakpoint();
    /**
     * Using JS for this is a bit hacky, but for now it's a one-off from a
     * design perspective, so we're treating it as such. If it keeps coming up,
     * consider making a `darkModeMobile` global class name to use.
     */
    const backgroundClassName =
        viewport.belowMobile && value.mobile_background_color
            ? value.mobile_background_color
            : undefined;

    return (
        <section
            className={concatClassNames([
                styles.root,
                backgroundClassName,
                viewport.aboveMobile ? "darkMode" : undefined,
            ])}
        >
            {value.media && (
                <MediaChooserBlock
                    value={value.media}
                    dependencies={{
                        ImageChooserBlock: (variantProps) => (
                            <ImageChooserBlock
                                {...variantProps}
                                attrs={{ className: styles.media }}
                                imageAttrs={{ className: styles.image }}
                            />
                        ),
                        VideoChooserBlock: (variantProps) => (
                            <VideoChooserBlock
                                {...variantProps}
                                attrs={{ className: styles.video }}
                                iOSFriendlyMutedAutoPlay={true}
                            />
                        ),
                        MediaEmbedBlock: (variantProps) => (
                            <MediaEmbedBlock
                                {...variantProps}
                                className={styles.embed}
                            />
                        ),
                    }}
                />
            )}
            <div className={styles.contentWrapper}>
                <div
                    className={concatClassNames([
                        styles.content,
                        value.alignment ? styles[value.alignment] : styles.left,
                    ])}
                >
                    <FeaturedRichTextBlock
                        value={{
                            color_options: backgroundClassName,
                            animation_options: "reveal-from-left",
                            body: value.heading,
                            cta: null,
                        }}
                        withinContainer={true}
                        attrs={{ className: styles.featuredRichText }}
                    />
                    {value.body && (
                        <AnimateOnScroll animation="fade-in-only">
                            <RichTextBlock value={value.body} />
                        </AnimateOnScroll>
                    )}
                </div>
            </div>
        </section>
    );
};
