import React from "react";

import { PrincipleDetailsBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { PrincipleDetail } from "./PrincipleDetail";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const PrincipleDetailsBlock = (props: Props) => {
    const { columns } = props.value;
    if (!columns) return null;

    const attrs = props.attrs || {};
    attrs.className = concatClassNames([
        attrs.className,
        styles.root,
        "darkMode",
    ]);

    return (
        <section {...attrs}>
            <div className={styles.top}>
                {columns.map((column, index) => (
                    <PrincipleDetail
                        key={`key-${index}`}
                        column={column}
                        columnIndex={index}
                    />
                ))}
            </div>
        </section>
    );
};
