import React from "react";

import { templates } from "@reactivated";

import { ContactForm } from "../components/ContactForm";
import { PageSkeleton } from "../components/PageSkeleton";
import { SpacerBlock } from "../components/SpacerBlock";
import { Toolbox } from "../components/Toolbox";

export const Template = (props: templates.ContactUsPageTemplate) => (
    <React.StrictMode>
        <PageSkeleton {...props.page}>
            <Toolbox value={props.page.body.value} />
            <ContactForm form={props.form} />
            <SpacerBlock
                value={{ height: "large", background_color: "white" }}
            />
        </PageSkeleton>
    </React.StrictMode>
);
