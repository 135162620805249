import { Root as AccordionRoot } from "@radix-ui/react-accordion";
import React from "react";

import { AccordionBlock as Value } from "@reactivated";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { RichTextBlock } from "../RichText";
import { AccordionItem } from "./AccordionItem";

import styles from "./index.module.scss";

interface Props {
    value: Value;
    withinContainer?: boolean;
}

export const AccordionBlock = ({ value, withinContainer = false }: Props) => {
    const { header, items } = value;
    return (
        <section>
            <AnimateOnScroll
                animation="up"
                attrs={{ className: !withinContainer ? styles.root : "" }}
            >
                {header && (
                    <RichTextBlock
                        value={header}
                        trimTerminalMargins="firstChild"
                    />
                )}
                <AccordionRoot
                    type="single"
                    collapsible
                    className={styles.listContainer}
                >
                    {items?.map((item, index) => (
                        <AccordionItem
                            key={`id-${index}`}
                            index={`id-${index}`}
                            value={item}
                        />
                    ))}
                </AccordionRoot>
            </AnimateOnScroll>
        </section>
    );
};
