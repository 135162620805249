import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import React, { FocusEvent } from "react";

import {
    HorizontalScrollBlock,
    HorizontalScrollSlide as HorizontalScrollSlideValue,
} from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { CarouselBlock } from "../CarouselBlock";
import { SlideContent } from "../CarouselBlock/CarouselItem";
import { ButtonBorderless } from "../Clickable";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";
import { MediaModal } from "./MediaModal";

import styles from "./SubBlock.module.scss";

export const SubBlock = (props: {
    value: HorizontalScrollSlideValue;
    colorOptions: HorizontalScrollBlock["color_options"];
    handleFocus: (event: FocusEvent<HTMLElement, Element>) => void;
}) => {
    const mediaCount = props.value.media?.length || 0;

    return (
        <Dialog>
            <AnimateOnScroll
                animation="up"
                attrs={{
                    className: concatClassNames([
                        styles.subBlock,
                        props.colorOptions && styles[props.colorOptions],
                        props.colorOptions ?? undefined,
                    ]),
                    tabIndex: 0,
                    onFocus: props.handleFocus,
                }}
                threshold={0.2}
            >
                <article className={styles.media}>
                    {mediaCount > 1 ? (
                        <CarouselBlock
                            value={{ media: props.value.media }}
                            withinContainer={true}
                            hideBottomOnTablet={true}
                        />
                    ) : (
                        <SlideContent
                            slide={props.value.media![0]}
                            hideCaptionOnTablet={true}
                        />
                    )}
                </article>
                <article className={styles.subBody}>
                    <div className={styles.subBodyInner}>
                        {props.value.show_chevron && (
                            <Svg
                                className={styles.chevron}
                                name="logo-chevron-only"
                                visuallyHiddenText={"logo chevron only"}
                            />
                        )}
                        {props.value.sub_body && (
                            <RichTextBlock
                                className={styles.richText}
                                value={props.value.sub_body}
                            />
                        )}
                        {props.value.cta?.display_name && (
                            <LinkSubBlock
                                className={styles.cta}
                                value={props.value.cta}
                                onDark={props.colorOptions === "darkMode"}
                            />
                        )}
                        {mediaCount > 1 && (
                            <div>
                                <DialogTrigger asChild>
                                    <ButtonBorderless
                                        className={styles.viewMore}
                                        icon="slides"
                                    >
                                        {props.value.media_cta || "View More"}
                                    </ButtonBorderless>
                                </DialogTrigger>
                            </div>
                        )}
                    </div>
                </article>
            </AnimateOnScroll>
            {props.value.media && (
                <MediaModal
                    value={props.value.media}
                    colorOptions={props.colorOptions}
                />
            )}
        </Dialog>
    );
};
