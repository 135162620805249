import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import React from "react";

import {
    HeroBlock as HeroBlockValue,
    MediaChooserBlock as MediaChooserBlockValue,
} from "@reactivated";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { Modal } from "../Modal";
import { RichTextBlock } from "../RichText";
import { Svg } from "../Svg";
import { HeroMediaBlock } from "./HeroMediaBlock";

import styles from "./index.module.scss";

const getMediaMeta = (
    media: MediaChooserBlockValue[number] | null,
): {
    videoTitle: string | null;
    videoThumbnail: string | null;
} => {
    switch (media?.type) {
        case "image": {
            return {
                videoTitle: media.value.title,
                videoThumbnail: media.value.url,
            };
        }
        case "video": {
            return {
                videoTitle: media.value.title,
                videoThumbnail: media.value.poster,
            };
        }
        case "embed": {
            return {
                videoTitle: media.value.title,
                videoThumbnail: media.value.thumbnail?.url || null,
            };
        }
        default: {
            return {
                videoTitle: null,
                videoThumbnail: null,
            };
        }
    }
};

export const HeroBlock = (props: {
    value: HeroBlockValue;
    type?: string | undefined;
    id?: string | undefined;
}) => {
    const { videoTitle, videoThumbnail } = getMediaMeta(
        props.value.long_video && props.value.long_video[0]
            ? props.value.long_video[0]
            : null,
    );
    return (
        <Dialog>
            <section
                className={concatClassNames([styles.root, "darkMode"])}
                id={props.id}
            >
                <HeroMediaBlock
                    value={props.value.media}
                    videoAttrs={{
                        controls: false,
                        autoPlay: true,
                        muted: true,
                        loop: true,
                    }}
                    iOSFriendlyMutedAutoPlay={true}
                />
                <div className={styles.contentWrapper}>
                    <AnimateOnScroll
                        animation="left"
                        wrapperAttrs={{
                            className: styles.contentOnMediaWrapper,
                        }}
                        attrs={{ className: styles.contentOnMedia }}
                    >
                        {props.value.header && (
                            <RichTextBlock
                                className={styles.header}
                                value={props.value.header}
                            />
                        )}
                        {props.value.copy && (
                            <RichTextBlock
                                className={styles.copy}
                                value={props.value.copy}
                            />
                        )}
                    </AnimateOnScroll>
                    {videoTitle && videoThumbnail && (
                        <AnimateOnScroll
                            animation="fade-in-only"
                            attrs={{
                                className: concatClassNames([
                                    styles.videoContainer,
                                    styles.showPlayButton,
                                ]),
                            }}
                        >
                            <DialogTrigger asChild>
                                <Clickable className={styles.playButton}>
                                    <Svg
                                        name="video-play-fill"
                                        visuallyHiddenText="Play Video"
                                    />
                                </Clickable>
                            </DialogTrigger>
                            <div className={styles.videoTitle}>
                                <p>{videoTitle}</p>
                            </div>

                            {props.value.long_video_thumbnail ? (
                                <ImageChooserBlock
                                    value={props.value.long_video_thumbnail}
                                    attrs={{
                                        className: styles.darkenVideoThumbnail,
                                    }}
                                />
                            ) : videoThumbnail ? (
                                <img
                                    src={videoThumbnail}
                                    alt=""
                                    className={styles.darkenVideoThumbnail}
                                />
                            ) : (
                                <></>
                            )}
                        </AnimateOnScroll>
                    )}
                </div>
            </section>
            <Modal
                className={styles.modal}
                contentLabel={videoTitle || "Video"}
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalVideo}>
                        <HeroMediaBlock
                            value={props.value.long_video}
                            videoAttrs={{
                                autoPlay: true,
                            }}
                            iOSFriendlyMutedAutoPlay={false}
                        />
                    </div>
                </div>
            </Modal>
        </Dialog>
    );
};
