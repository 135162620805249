import React from "react";

import { ScrollNavMenuBlock as BaseScrollNavMenuBlock } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";

import { ScrollNavMenuContainer } from "./ScrollNavMenuContainer";

import styles from "./index.module.scss";

// Pass through ScrollNavMenuItemBlock—we don't need to customize it.
export { ScrollNavMenuItemBlock } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";

/**
 * Renders a sticky "table of contents" nav bar for navigating between sections
 * in the page. Items in the ToC are derived from whatever ScrollNavMenuItemBlock
 * components are also rendered on the page.
 */
export const ScrollNavMenuBlock = (
    props: React.ComponentPropsWithoutRef<typeof BaseScrollNavMenuBlock>,
) => (
    <BaseScrollNavMenuBlock
        {...props}
        classNames={{
            menuItem: styles.menuItem,
            menuItemSelected: styles.menuItemSelected,
        }}
        dependencies={{ ScrollNavMenuContainer }}
    />
);
