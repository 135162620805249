import React from "react";

import { StaggeredImageTextBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { StaggeredTile } from "./StaggeredTile";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const StaggeredImageTextBlock = (props: Props) => {
    const attrs = props.attrs || {};
    attrs.className = concatClassNames([attrs.className, styles.root]);

    const tiles =
        props.value.staggered_tiles?.map((tile, index) => ({
            ...tile,
            originalIndex: index,
        })) || [];
    const oddTiles = tiles.filter((_, index) => index % 2);
    const evenTiles = tiles.filter((_, index) => !(index % 2));
    return (
        <section {...attrs}>
            <article className={styles.wrapper}>
                <div className={styles.column}>
                    {evenTiles?.map((tile, index) => (
                        <StaggeredTile
                            key={`${index}`}
                            staggeredTile={tile}
                            isTileFeatured={!tile.image?.url}
                            index={tile.originalIndex}
                        />
                    ))}
                </div>
                <div className={styles.column}>
                    {oddTiles?.map((tile, index) => (
                        <StaggeredTile
                            key={`${index}`}
                            staggeredTile={tile}
                            isTileFeatured={!tile.image?.url}
                            index={tile.originalIndex}
                        />
                    ))}
                </div>
            </article>
        </section>
    );
};
