import React from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const PlaceholderImage = ({ className }: { className?: string }) => (
    <div
        className={concatClassNames([styles.placeholder, className])}
        aria-hidden="true"
    >
        <Svg className={styles.placeholderLogo} name="logo-vertical" />
    </div>
);
