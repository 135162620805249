import React from "react";

import { NewsHeroBlock as NewsHeroBlockValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { ButtonBorderless } from "../../components/Clickable";
import { PlaceholderImage } from "../Placeholder";
import { EventInfo } from "./EventInfo";
import { NewsTags } from "./NewsTags";

import styles from "./index.module.scss";

export interface Props {
    value: NewsHeroBlockValue;
}

export const NewsHeroBlock = (props: Props) => (
    <section className="darkMode">
        <div className={styles.inner}>
            <article className={styles.imageContainer}>
                {props.value.image ? (
                    <ImageChooserBlock
                        value={props.value.image}
                        imageAttrs={{ className: styles.image }}
                    />
                ) : (
                    <PlaceholderImage className={styles.placeholder} />
                )}
            </article>
            <article className={styles.content}>
                <NewsTags tags={props.value.tags} />
                <h2 className={styles.header}>{props.value.headline}</h2>
                {props.value.copy && (
                    <p className={styles.copy}>{props.value.copy}</p>
                )}
                <EventInfo
                    attrs={{ className: styles.eventInfo }}
                    dateRange={props.value.event_daterange}
                    location={props.value.event_location}
                />
                <ButtonBorderless href={props.value.page_url} onDark>
                    Read more
                </ButtonBorderless>
            </article>
        </div>
    </section>
);
