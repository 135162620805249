import React, { useContext, useEffect, useRef, useState } from "react";

import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { pageContext } from "../../utils/hooks";
import { Svg } from "../Svg";

import styles from "./PageInfo.module.scss";

export const useNavIsStuck = (
    containerElem: React.RefObject<HTMLDivElement>,
) => {
    const [isStuck, setIsStuck] = useState<boolean>(false);

    const onScroll = () => {
        if (!containerElem.current) {
            return;
        }
        const boundingBox = containerElem.current.getBoundingClientRect();
        setIsStuck(boundingBox.top < 13);
    };

    // Attach the event handler to update the active item whenever the user
    // scrolls the window.
    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    // Return the active nav item
    return isStuck;
};

export const PageInfo = () => {
    const page = useContext(pageContext);
    const containerElem = useRef<HTMLDivElement>(null);
    const navIsStuck = useNavIsStuck(containerElem);
    return (
        <div
            className={classNames([
                [styles.pageInfo, true],
                [styles.pageInfoVisible, navIsStuck],
            ])}
            ref={containerElem}
        >
            <Svg
                name="logo-chevron-only"
                visuallyHiddenText="Supernal Chevron"
                className={styles.logo}
            />
            <h1 className={styles.pageTitle}>{page.title}</h1>
        </div>
    );
};
