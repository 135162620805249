import { CSSProperties } from "react";

export interface AnimationState {
    midGray: CSSProperties;
    darkGray: CSSProperties;
}

type AnimationStyles = ReadonlyArray<Readonly<AnimationState>>;

export const circleAnimationStyles: AnimationStyles = [
    // Tab 1
    {
        midGray: { transform: "translate(0, 0) scale(0.65)" },
        darkGray: { transform: "translate(0, 0) scale(0.35)" },
    },
    // Tab 2
    {
        midGray: { transform: "translate(-10%, 0) scale(0.2)" },
        darkGray: { transform: "translate(10%, 0) scale(0.2)" },
    },
    // Tab 3
    {
        midGray: { transform: "translate(0, 17.5%) scale(0.65)" },
        darkGray: { transform: "translate(0, 32.5%) scale(0.35)" },
    },
    // Tab 4
    {
        midGray: { transform: "translate(12.5%, -12.5%) scale(0.65)" },
        darkGray: { transform: "translate(-23%, 23%) scale(0.35)" },
    },
];
