import React, { useEffect, useRef, useState } from "react";

import { NewsTickerBlock as Value } from "@reactivated";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { Svg } from "../Svg";

import styles from "./index.module.scss";

export interface IProps {
    value: Value;
    id?: string | undefined;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export interface CustomCss extends React.CSSProperties {
    "--duration": string;
}

const TickerText = ({ text }: { text: string | null }) => (
    <p key={text} className={styles.tickerText}>
        <Svg name="logo-chevron-only" aria-hidden="true" />
        {text}
    </p>
);

/**
 * A marquee/ticker component. Features:
 * - Custom duration that is set based on the number of characters in all of
 *   the ticker text
 * - Should be compatible with any kind of marquee length; this can add
 *   (aria-hidden) duplicates depending on how much of the overall width
 *   the text takes up
 */
export const NewsTickerBlock = (props: IProps) => {
    const root = useRef<HTMLDivElement | null>(null);
    const allTickerText = useRef<HTMLDivElement | null>(null);
    const [duplicates, setDuplicates] = useState(1);

    const attrs = props.attrs || {};
    attrs.className = concatClassNames([attrs.className, styles.root]);

    const { ticker_items } = props.value;

    const characterCount = ticker_items?.map((ticker) => ticker.text).join("")
        .length;
    const duration = characterCount ? `${characterCount / 4}s` : "10s";

    const tickerItems =
        ticker_items?.filter((item) => typeof item.text === "string") || [];

    useEffect(() => {
        const wrapperWidth = root.current?.getBoundingClientRect().width;
        const allTickerWidth =
            allTickerText.current?.getBoundingClientRect().width;
        if (wrapperWidth && allTickerWidth) {
            setDuplicates(Math.ceil(wrapperWidth / allTickerWidth));
        }
    }, []);

    return tickerItems.length > 0 ? (
        <section
            {...attrs}
            id={props.id}
            style={{ "--duration": duration } as CustomCss}
            ref={root}
        >
            <div className={styles.wrapper}>
                <div className={styles.ticker} ref={allTickerText}>
                    {tickerItems.map(({ text }, index) => (
                        <TickerText key={`${index}`} text={text} />
                    ))}
                </div>
                {Array(duplicates)
                    .fill(0)
                    .map((_, index) => (
                        <div
                            key={`duplicates${index}`}
                            className={styles.ticker}
                            aria-hidden="true"
                        >
                            {tickerItems.map(({ text }, index) => (
                                <TickerText key={`${index}`} text={text} />
                            ))}
                        </div>
                    ))}
            </div>
        </section>
    ) : (
        <></>
    );
};
