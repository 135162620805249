import React from "react";

import { VehicleLandingBlock as Value } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";

import styles from "./index.module.scss";

export const VehicleLandingBlock = (props: { value: Value }) => (
    <section className={styles.root}>
        {props.value.media && <MediaChooserBlock value={props.value.media} />}
    </section>
);
