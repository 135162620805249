import { format, isValid, parseISO } from "date-fns";
import React from "react";

import { TimelineSubBlock as Value } from "@reactivated";

import styles from "./TimelineEntry.module.scss";

interface Props {
    value: Value;
}

export const TimelineEntry = ({ value: { date, headline } }: Props) => {
    const dateObj = parseISO(date || "");

    return (
        <div
            className={styles.timeline}
            data-year={isValid(dateObj) && format(dateObj, "yy")}
        >
            <time className={styles.date} dateTime={date || undefined}>
                {isValid(dateObj) && format(dateObj, "yyyy")}
            </time>
            <h3 className={styles.heading}>{headline}</h3>
        </div>
    );
};
