import React from "react";

import { MenuItemBlock as MenuItemBlockValue } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { LinkSubBlock } from "../Clickable/LinkSubBlock";

import styles from "./MenuItem.module.scss";

interface IProps {
    id: number | null;
    value: MenuItemBlockValue;
    totalItems: number;
}

interface CustomCss extends React.CSSProperties {
    "--percent": string;
}

export const MenuItemBlock = (props: IProps) => {
    return (
        <li
            className={styles.menuListItem}
            style={
                {
                    "--percent": props.id
                        ? `${(props.id / props.totalItems) * 100}%`
                        : "0%",
                } as CustomCss
            }
        >
            {props.value.link && (
                <LinkSubBlock
                    key={props.id}
                    className={styles.menuItem}
                    value={{
                        ...props.value.link,
                        link_style: "unstyled",
                    }}
                />
            )}
            {props.value.image && (
                <ImageChooserBlock
                    attrs={{ className: styles.image }}
                    value={props.value.image}
                />
            )}
        </li>
    );
};
