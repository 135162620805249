import React from "react";

import { NewsFeaturedArticlesBlock } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { ButtonBorderless } from "../Clickable";
import { PlaceholderImage } from "../Placeholder";

import styles from "./ArticleTile.module.scss";

export interface Props {
    article: NewsFeaturedArticlesBlock["articles"][number];
}

export const ArticleTile = ({ article }: Props) => {
    if (!article) return <></>;

    return (
        <article className={styles.root}>
            {article.image ? (
                <ImageChooserBlock
                    value={article.image}
                    attrs={{ className: styles.articleImage }}
                />
            ) : (
                <PlaceholderImage className={styles.placeholder} />
            )}
            <p className={styles.articleDate}>
                {article.article_tags && article.article_tags.length > 0 && (
                    <>
                        <span className={styles.tags}>
                            {article.article_tags.join(", ")}
                        </span>
                        <span className={styles.divider} aria-hidden="true" />
                    </>
                )}
                <span className={styles.date}>{article.article_date}</span>
            </p>
            <h3 className={styles.articleTitle}>{article.article_title}</h3>
            <ButtonBorderless
                target={
                    article.article_type === "ExternalArticleAbstractPage"
                        ? "_blank"
                        : undefined
                }
                className={styles.cta}
                href={article.article_url}
            >
                Read More
            </ButtonBorderless>
        </article>
    );
};
