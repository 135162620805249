import React, { PropsWithChildren } from "react";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";

import styles from "./ContentGrid.module.scss";

interface Props extends PropsWithChildren {
    /**
     * For the use case of "one row of articles" this makes more sense
     * than the pagination case
     */
    hideLastOnTablet: boolean;
    animate: boolean;
}

export const ContentGrid = (props: Props) => (
    <AnimateOnScroll
        animation={props.animate ? "up" : ""}
        lowerMobileThreshold={true}
        attrs={{
            className: concatClassNames([
                styles.content,
                props.hideLastOnTablet ? styles.hideLastOnTablet : "",
            ]),
        }}
    >
        {props.children}
    </AnimateOnScroll>
);
