import React, { useEffect, useState } from "react";

import {
    ImageChooserBlockOrNull,
    TabbedSubBlockList,
    TabbedSubBlockListOrNull,
    TabbedBlock as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { TabbedTile } from "./TabbedTile";

import styles from "./index.module.scss";

export interface IProps {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

/**
 * If a default image has been added in the CMS, it overrides any
 * tab-specific images. If not, use the tab's image if there is one.
 */
const getHoveredTabImage = (
    tabs: TabbedSubBlockList,
    hoveredTabIndex: number,
    default_image: ImageChooserBlockOrNull | undefined,
) => {
    const currentImage = default_image ?? tabs[hoveredTabIndex].image;
    return currentImage ? (
        <ImageChooserBlock
            value={currentImage}
            attrs={{ className: styles.picture }}
            imageAttrs={{ className: styles.image }}
        />
    ) : (
        <></>
    );
};

/**
 * If there's no default image and if the first tab has no image, we want to
 * show some image by default.
 */
const getInitialHoverTab = (
    default_image?: ImageChooserBlockOrNull,
    tabs?: TabbedSubBlockListOrNull,
) => {
    if (default_image) return 0;
    if (!tabs) return 0;
    const index = tabs.findIndex((tab) => !!tab.image);
    return index > -1 ? index : 0;
};

export const TabbedBlock = (props: IProps) => {
    const { default_image, tabs, color_options } = props.value;
    const [hoveredTabIndex, setHoveredTabIndex] = useState(() =>
        getInitialHoverTab(default_image, props.value.tabs),
    );
    /**
     * If a scroll nav menu is present, then the sticky image gets extra padding
     */
    const [hasScrollNavMenu, setHasScrollNavMenu] = useState(false);

    const attrs = { ...props.attrs };
    attrs.className = concatClassNames([attrs.className, color_options]);

    useEffect(() => {
        if (document.querySelector('[data-id="scroll-nav-menu"]') !== null)
            setHasScrollNavMenu(true);
    }, []);

    if (!tabs || tabs.length === 0)
        return <p>Add at least one tab for this block to show.</p>;

    return (
        <section {...attrs}>
            <AnimateOnScroll animation="up">
                <article
                    className={
                        !default_image
                            ? styles.tabsWithoutDefaultImage
                            : styles.tabsWithDefaultImage
                    }
                >
                    <div
                        className={concatClassNames([
                            styles.imageBox,
                            hasScrollNavMenu ? styles.hasScrollNavMenu : "",
                        ])}
                    >
                        {getHoveredTabImage(
                            tabs,
                            hoveredTabIndex,
                            default_image,
                        )}
                    </div>
                    <div
                        tabIndex={0}
                        role="region"
                        aria-label="Scrollable Content"
                        className={concatClassNames([
                            styles.tabsContent,
                            !default_image
                                ? styles.contentWithoutDefaultImage
                                : undefined,
                        ])}
                    >
                        {tabs.map((tab, index) => (
                            <TabbedTile
                                key={`key-${index}`}
                                tab={tab}
                                tabIndex={index}
                                isHovered={hoveredTabIndex === index}
                                setHoveredTabIndex={setHoveredTabIndex}
                                default_image={default_image}
                            />
                        ))}
                    </div>
                </article>
            </AnimateOnScroll>
        </section>
    );
};
