import { Trigger as DialogTrigger } from "@radix-ui/react-dialog";
import React from "react";

import { Clickable } from "@thelabnyc/thelabui/src/components/Clickable";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import { Svg } from "../Svg";
import { TeamMember } from "./types";

import styles from "./TeamMemberTile.module.scss";

export const TeamMemberTile = (props: {
    member: TeamMember;
    onClick: () => void;
}) => {
    return (
        <DialogTrigger asChild>
            <Clickable className={styles.member} onClick={props.onClick}>
                {props.member.headshot && (
                    <ImageChooserBlock value={props.member.headshot} />
                )}
                <article className={styles.memberInfo}>
                    <div className={styles.nameContainer}>
                        <h3 className={styles.name}>{props.member.title}</h3>
                        <h3>
                            <Svg
                                name="plus"
                                visuallyHiddenText="plus icon for memeber detail"
                            />
                        </h3>
                    </div>
                    <p className={styles.jobTitle}>{props.member.job_title}</p>
                </article>
            </Clickable>
        </DialogTrigger>
    );
};
