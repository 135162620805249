import React, { useState } from "react";

import { NewsArticleGridBlock as Value } from "@reactivated";

import { ButtonFilled } from "../../components/Clickable";
import { AnimateOnScroll } from "../AnimateOnScroll";
import { ArticleTile } from "../NewsFeaturedArticlesBlock/ArticleTile";
import { ContentGrid } from "../NewsFeaturedArticlesBlock/ContentGrid";

import styles from "./index.module.scss";

type Article = Value["articles"][number];

export interface Props {
    value: Value;
}

export const NewsArticleGridBlock = ({ value }: Props) => {
    const [nextURL, setNextURL] = useState<string | null | undefined>(
        value.next_url,
    );
    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState<Article[]>(value.articles);

    const onShowMore = async () => {
        if (!nextURL) return;
        setLoading(true);
        const response = await fetch(nextURL);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const page: Value = await response.json();
        setNextURL(page.next_url ?? null);
        setLoading(false);
        setArticles(articles.concat(page.articles));
    };

    return (
        <section className={styles.root}>
            <AnimateOnScroll animation="left">
                <h2 className={styles.header}>{value.header}</h2>
            </AnimateOnScroll>
            <ContentGrid hideLastOnTablet={false} animate={true}>
                {articles?.map((article) => (
                    <ArticleTile key={article.id} article={article} />
                ))}
            </ContentGrid>
            {value.articles.length > 0 && nextURL !== null && (
                <div className={styles.showMoreButton}>
                    <ButtonFilled disabled={loading} onClick={onShowMore}>
                        Show more
                    </ButtonFilled>
                </div>
            )}
        </section>
    );
};
