import React from "react";

import {
    NewsroomToolbox as NewsroomToolboxValue,
    Toolbox as Value,
} from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";

import { AccordionBlock } from "../AccordionBlock";
import { AlignedContainerBlock } from "../AlignedContainerBlock";
import { AlignedHeroBlock } from "../AlignedHeroBlock";
import { CarouselBlock } from "../CarouselBlock";
import { ColumnGridBlock } from "../ColumnGridBlock";
import { EcosystemBlock } from "../EcosystemBlock";
import { EmailSignupBlock } from "../EmailSignupBlock";
import { EmailSignupHeroBlock } from "../EmailSignupHeroBlock";
import { EventsBlock } from "../EventsBlock";
import { FeaturedRichTextBlock } from "../FeaturedRichTextBlock";
import { HeroBlock } from "../HeroBlock";
import { HomepageHeroBlock } from "../HomepageHeroBlock";
import { HorizontalScrollBlock } from "../HorizontalScrollBlock";
import { MediaBlock } from "../MediaBlock";
import { MediaInquiryBlock } from "../MediaInquiryBlock";
import { MediaPromoBlock } from "../MediaPromoBlock";
import { NewsFeaturedArticlesBlock } from "../NewsFeaturedArticlesBlock";
import { NewsArticleGridBlock } from "../NewsGridArticleBlock";
import { NewsHeroBlock } from "../NewsHeroBlock";
import { NewsTickerBlock } from "../NewsTickerBlock";
import { PrincipleDetailsBlock } from "../PrincipleDetailsBlock";
import { PrinciplesBlock } from "../PrinciplesBlock";
import {
    ScrollNavMenuBlock,
    ScrollNavMenuItemBlock,
} from "../ScrollNavMenuBlock";
import { SpacerBlock } from "../SpacerBlock";
import { StaggeredImageTextBlock } from "../StaggeredImageTextBlock";
import { TabbedBlock } from "../TabbedBlock";
import { TeamMembersBlock } from "../TeamMembersBlock";
import { TimelineBlock } from "../TimelineBlock";
import { ValuePropsBlock } from "../ValuePropsBlock";
import { VehicleLandingBlock } from "../VehicleLandingBlock";

const toolboxComponents: StreamFieldBlockMap<Value> = {
    accordion: AccordionBlock,
    aligned_container: AlignedContainerBlock,
    aligned_hero: AlignedHeroBlock,
    carousel: CarouselBlock,
    column_grid: ColumnGridBlock,
    ecosystem: EcosystemBlock,
    email_signup: EmailSignupBlock,
    email_signup_hero: EmailSignupHeroBlock,
    events: EventsBlock,
    featured_rich_text: FeaturedRichTextBlock,
    hero: HeroBlock,
    homepage_hero: HomepageHeroBlock,
    horizontal_scroll: HorizontalScrollBlock,
    image_chooser: ImageChooserBlock,
    linksubblock: () => null,
    media: MediaBlock,
    media_promo: MediaPromoBlock,
    news_ticker: NewsTickerBlock,
    news_curated_articles: NewsFeaturedArticlesBlock,
    news_featured_articles: NewsFeaturedArticlesBlock,
    news_hero: NewsHeroBlock,
    principle_details: PrincipleDetailsBlock,
    principles: PrinciplesBlock,
    scroll_nav_menu: ScrollNavMenuBlock,
    scroll_nav_menu_item: ScrollNavMenuItemBlock,
    spacer: SpacerBlock,
    staggered_image_text: StaggeredImageTextBlock,
    tabbed: TabbedBlock,
    timeline: TimelineBlock,
    team_members: TeamMembersBlock,
    value_props: ValuePropsBlock,
    vehicle_landing: VehicleLandingBlock,
};

const newsroomToolboxComponents: StreamFieldBlockMap<NewsroomToolboxValue> = {
    email_signup: EmailSignupBlock,
    events: EventsBlock,
    featured_rich_text: FeaturedRichTextBlock,
    media_inquiry: MediaInquiryBlock,
    news_hero: NewsHeroBlock,
    news_article_grid: NewsArticleGridBlock,
    scroll_nav_menu: ScrollNavMenuBlock,
    scroll_nav_menu_item: ScrollNavMenuItemBlock,
    spacer: SpacerBlock,
};

export const Toolbox = (props: { value: Value }) => {
    return (
        <StreamField
            tagName={React.Fragment}
            components={toolboxComponents}
            value={props.value}
        />
    );
};

export const NewsroomToolboxBlock = (props: {
    value: NewsroomToolboxValue;
}) => {
    return (
        <StreamField
            components={newsroomToolboxComponents}
            value={props.value}
        />
    );
};
