import React from "react";

import { MediaPromoBlock as Value } from "@reactivated";

import { MediaChooserBlock } from "@thelabnyc/thelabui/src/components/MediaChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { AnimateOnScroll } from "../AnimateOnScroll";
import { LinkSubBlock } from "../Clickable/LinkSubBlock";
import { RichTextBlock } from "../RichText";

import styles from "./index.module.scss";

export const MediaPromoBlock = (props: { value: Value }) => {
    const { body, link, media } = props.value;

    return (
        <AnimateOnScroll animation="curtains">
            <section
                className={concatClassNames([
                    styles.root,
                    "darkMode", // Always darkMode with background media
                ])}
            >
                {media && <MediaChooserBlock value={media} />}
                <div className={styles.gridContainer}>
                    <div className={styles.inner}>
                        <div className={styles.content}>
                            <AnimateOnScroll animation="left" threshold={1}>
                                {body && (
                                    <RichTextBlock
                                        className={styles.richText}
                                        value={body}
                                    />
                                )}
                                {link?.display_name && (
                                    <LinkSubBlock
                                        value={link}
                                        className={styles.cta}
                                    />
                                )}
                            </AnimateOnScroll>
                        </div>
                    </div>
                </div>
            </section>
        </AnimateOnScroll>
    );
};
